<template>
  <div class="d-flex mt-3 index-header">
    <div class="ml-auto">
      <h2 class="mb-3">
        {{ title }}
      </h2>
      <p class="text-muted">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'description']
}
</script>
