<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="sm-stop-edit" v-if="loading"></div>
        <div class="modal-card card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t('New session') }}
            </h4>
            <button type="button" class="close" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-12">
                    {{ $t('Point of sale') }}
                  </label>
                  <div class="icon-input col-md-12">
                    <span>
                      <i class="far fa-cash-register"></i>
                    </span>
                    <input type="text" disabled class="form-control" :value="pointOfSaleName" />
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-12">
                    {{ $t('Withdrawn safe') }}
                  </label>
                  <div class="icon-input col-md-12">
                    <span>
                      <i class="far fa-treasure-chest"></i>
                    </span>
                    <input type="text" disabled class="form-control" v-model="safeName" />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-12">
                    {{ $t('Discounted amount') }}
                  </label>
                  <div class="icon-input col-md-12">
                    <span>
                      <i class="far fa-dollar-sign"></i>
                    </span>
                    <input
                      type="number"
                      :class="{ 'is-invalid': errors.balance }"
                      class="form-control"
                      v-model="form.balance"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-12">
                    {{ $t('Notes') }}
                  </label>
                  <div class="col-md-12">
                    <textarea class="form-control" v-model="form.notes"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="$emit('close')">
                  <i class="far fa-times"></i>
                  {{ $parent.$t('Close') }}
                </button>
                <button @click="submit()" class="btn btn-dark" v-if="!loading">
                  <i class="far fa-save"></i>
                  {{ $parent.$t('Create') }}
                </button>
                <button class="btn btn-dark" v-if="loading">
                  {{ $parent.$t('Loading') }}
                  <span class="loading-s1 sm-j">.</span>
                  <span class="loading-s2 sm-j">.</span>
                  <span class="loading-s3 sm-j">.</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Vue from 'vue'
import axios from 'axios'
import { ref, defineProps, defineEmits } from 'vue'
import { createToastInterface } from 'vue-toastification'

const toast = createToastInterface()

const form = ref({
  safe_id: '',
  balance: ''
})
const errors = ref({
  to_id: false,
  balance: false
})

const loading = ref(false)

const submit = () => {
  loading.value = true
  axios
    .post(Vue.prototype.$linkGenerator('/pointofsales/' + props.id + '/newSession'), form.value)
    .then(() => {
      Vue.prototype.$updateDatabase(['pointOfSales'])
      toast.success('New session has been started successfully', {
        position: 'top-center'
      })
      emit('close')
      loading.value = false
    })
}

const pointOfSale = Vue.prototype.$database.pointOfSales.find((el) => el.id == props.id)
const pointOfSaleName = pointOfSale.name

var safe = Vue.prototype.$database.safes.find((el) => el.id == pointOfSale.safe_id)
const safeName = safe.name
form.value.safe_id = safe.id
form.value.balance = pointOfSale.balance

const props = defineProps(['id'])
const emit = defineEmits(['close'])
</script>
