<template>
  <th>
    <span
      class="text-muted"
      :class="sort != null ? 'list-sort' : ''"
      @click="sort != null ? changeOrder(sort) : ''"
    >
      {{ text }}
    </span>
  </th>
</template>
<script>
export default {
  props: ['text', 'sort'],
  methods: {
    changeOrder(name) {
      const parentParams = this.$parent.$parent.$parent.params
      const parentItems = this.$parent.$parent.$parent.items

      parentParams.page = 1

      const parseValue = (value) => {
        if (typeof value === 'string') {
          // Try to parse the string as a number, removing commas
          const numberValue = parseFloat(value.replace(/,/g, ''))
          return isNaN(numberValue) ? value : numberValue
        }
        return value
      }

      const compare = (a, b) => {
        const valA = parseValue(a[name])
        const valB = parseValue(b[name])

        if (typeof valA === 'string' && typeof valB === 'string') {
          return valA.localeCompare(valB)
        }
        return valA - valB
      }

      if (parentParams.orderType === 'asc' && parentParams.orderBy === name) {
        parentParams.orderType = 'desc'
        parentItems.sort((a, b) => compare(b, a))
      } else {
        parentParams.orderType = 'asc'
        parentItems.sort(compare)
      }

      parentParams.orderBy = name
    }
  }
}
</script>
