<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a
                    >{{ $t('Salary') }} <strong> {{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary d-none d-md-inline-block"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-usd-square"></i>
              {{ $t('Salary details') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <table class="table table-sm table-nowrap customed-table-small">
                  <tbody>
                    <tr>
                      <th scope="row">
                        {{ $t('Operation code') }}
                      </th>
                      <td>{{ item.code != null ? item.code : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Date') }}
                      </th>
                      <td>{{ $dateFormat(item.date) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Administrator') }}
                      </th>
                      <td>{{ item.user ? item.user.name : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('Reference') }}
                      </th>
                      <td>
                        {{ item.refrance != null ? item.refrance : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('That about') }}
                      </th>
                      <td>
                        {{ item.description != null ? item.description : '--' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-3">
                <div class="price-show">
                  <div class="price-content">
                    <p>{{ $t('Cost') }}</p>
                    <h1>{{ $moneyFormat(item.cost) }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-user-alt"></i>
              {{ $t('Payment details') }}
            </h4>
          </div>
          <div class="data-table-header card-body">
            <div class="row">
              <div class="col-xl-6">
                <div>
                  <div class="row">
                    <div class="col-xl-12 col-6">
                      {{ $t('Employee name') }}
                    </div>
                    <div class="col-xl-12 col-6">
                      <router-link :to="'/employees/' + item.employee.id" v-if="item.employee">{{
                        item.employee.name
                      }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('Cost') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.cost) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <showFile />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'

export default {
  data() {
    return {
      path: '/employeesSalaries',
      item: {}
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    }
  },
  components: {
    showFile
  }
}
</script>
<style></style>
