<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Accounting')" :description="$t('Balance sheet')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Report options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <dateFilter class="col-md-3" />
              <searchInput
                class="col-md-3"
                :title="$t('User')"
                :plaseholder="$t('Choose a user')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="$database.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('PointOfSale')"
                :plaseholder="$t('Choose a selling point')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t('Session no') }}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('Enter the session number')"
                  />
                </div>
              </div>
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <div class="card" id="reportShow">
            <div class="card-header col-12 p-4">
              <h4 class="card-header-title col-4">
                <i class="fal fa-chart-bar"></i>
                {{ $t('Balance sheet') }}
              </h4>
              <div style="text-align: center" class="col-4">
                <p class="mt-1" v-if="userName">
                  <strong>{{ $t('User name') }} </strong>{{ userName }}
                </p>

                <p class="mt-1" v-if="pointOfSaleName">
                  <strong>{{ $t('Point of sale') }} </strong>{{ pointOfSaleName }}
                </p>

                <p class="mt-1" v-if="sessionNumber">
                  <strong> {{ $t('Session no') }} </strong>{{ sessionNumber }}
                </p>
              </div>
              <p class="col-4 text-left">
                {{ $t('From the date') }}
                {{ searchStartDate ? searchStartDate : '--' }}
                {{ $t('into') }}
                {{ searchEndDate ? searchEndDate : '--' }}
              </p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-sm table-nowrap card-table table-hover">
                    <tbody class="list" v-if="loading != 1">
                      <tr class="clum-head">
                        <td colspan="100" class="text-center">
                          <strong>{{ $t('Assets') }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t('Safes') }}</strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <strong class="p-4">{{ $t('Sales') }} {{ $t('Invoices') }}</strong>
                        </td>
                        <td>{{ totalInvoices }} {{ $option.currency }}</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>{{ $t('Assets in Circulation') }}</strong>
                        </td>
                        <td></td>
                      </tr>

                      <tr>
                        <td>
                          <strong class="p-4">{{ $t('Safes') }}</strong>
                        </td>
                        <td>{{ totalBills }} {{ $option.currency }}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong class="p-4">{{ $t('Stores') }}</strong>
                        </td>
                        <td>{{ totalExpenses }} {{ $option.currency }}</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>{{ $t('Debtors') }}</strong>
                        </td>
                        <td></td>
                      </tr>

                      <tr>
                        <td>
                          <strong class="p-4">{{ $t('Customers') }}</strong>
                        </td>
                        <td>{{ totalExpenses }} {{ $option.currency }}</td>
                      </tr>

                      <tr class="clum-head">
                        <td>{{ $t('Total assets') }}</td>
                        <td>
                          {{ totalInvoices - totalBills - totalExpenses }}
                          {{ $option.currency }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-sm table-nowrap card-table table-hover">
                    <tbody class="list" v-if="loading != 1">
                      <tr class="clum-head">
                        <td colspan="100" class="text-center">
                          <strong>{{ $t('Antagonists') }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t('Creditors') }}</strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <strong class="p-4">{{ $t('Suppliers') }}</strong>
                        </td>
                        <td>{{ totalInvoices }} {{ $option.currency }}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t('Profits') }} / {{ $t('Capital losses') }}</strong>
                        </td>
                        <td>{{ totalBills }} {{ $option.currency }}</td>
                      </tr>
                      <tr class="clum-head">
                        <td>{{ $t('Total liabilities') }}</td>
                        <td>
                          {{ totalInvoices - totalBills - totalExpenses }}
                          {{ $option.currency }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / .row -->
  </div>
</template>
<script>
import axios from 'axios'

import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
export default {
  data() {
    return {
      path: '/accounting/profitsAndLosses',
      logo: localStorage.getItem('logo'),
      invoices: [],
      bills: [],
      expenses: [],

      totalInvoices: 0,
      totalBills: 0,
      totalExpenses: 0,
      loading: 0,

      params: {
        store_id: '',
        user_id: '',
        point_of_sale_id: '',
        session_number: '',
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      searchStartDate: '',
      searchEndDate: '',

      storeName: '',
      userName: '',
      pointOfSaleName: '',
      sessionNumber: ''
    }
  },
  methods: {
    getData() {
      this.loading = 1
      this.invoices = {}
      this.bills = {}
      this.expenses = {}

      this.totalInvoices = 0
      this.totalBills = 0
      this.totalExpenses = 0

      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.invoices = response.data.invoices
        this.bills = response.data.bills
        this.expenses = response.data.expenses

        this.searchStartDate = this.startDate
        this.searchEndDate = this.endDate

        this.invoices.forEach((item) => {
          this.totalInvoices += item.total
        })

        this.bills.forEach((item) => {
          this.totalBills += item.total
        })

        this.expenses.forEach((item) => {
          this.totalExpenses += item.cost
        })

        this.loading = 0
      })
    },
    print() {
      var text = document.getElementById('reportShow').innerHTML
      var myframe = document.getElementById('printIframe')
      myframe.contentDocument.body.innerHTML =
        '<div class="text-center"> <img src="' +
        this.logo +
        '" class="img-fluid mb-4" style="max-width: 4.5rem;" v-if="$option.image != null"><h2 class="mb-2">' +
        this.$option.name +
        '</h2></div>' +
        text
      setTimeout(function () {
        myframe.focus()
        myframe.contentWindow.print()
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    this.$updateDatabase(['users'])
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
  },
  components: {
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
