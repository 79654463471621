<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          <i class="far fa-exclamation-triangle"></i>
          {{ $t('over due Invoices') }}
        </h4>
      </div>
      <div class="card-body whitout-padding">
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">{{ $t('customer') }}</th>
                <th scope="col">{{ $t('invoice') }}</th>
                <th scope="col">{{ $t('dueDate') }}</th>
                <th scope="col">{{ $t('Balance') }}</th>
                <th scope="col">{{ $t('status') }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="items.length == 0 && loaded">
                <td colspan="7" class="empty-table">
                  <i class="fas fa-inbox d-block"></i>
                  {{ $t('No Bills') }}
                  <p>
                    {{ $t('Try Adding Some Items To The Table And Try Again') }}
                  </p>
                </td>
              </tr>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <router-link :to="'/customers/' + item.customer.id" v-if="item.customer">{{
                    item.customer.name
                  }}</router-link>
                </td>
                <td>
                  <router-link :to="'/invoices/' + item.id">{{ item.code }}</router-link>
                </td>
                <td>{{ item.expiry_date }}</td>
                <td>{{ $moneyFormat(item.rest) }}</td>
                <th scope="row">
                  <div class="badge badge-soft-dark" v-if="item.status == 0">
                    {{ $t('draft') }}
                  </div>
                  <div class="badge badge-soft-primary" v-if="item.status == 1">
                    {{ $t('sent') }}
                  </div>
                  <div class="badge badge-soft-primary" v-if="item.status == 2">
                    {{ $t('seen') }}
                  </div>
                  <div class="badge badge-soft-success" v-if="item.status == 3">
                    {{ item.rest == 0 ? $t('driven') : $t('partially Driven') }}
                  </div>
                  <div class="badge badge-soft-danger" v-if="item.status == 4">
                    {{ $t('canceled') }}
                  </div>
                </th>
                <td>
                  <a :href="'/invoices/' + item.id + '/pdf'" download>
                    <i class="fa fa-download"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <a href="#" class="">{{ $t('viewAll') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['items']
}
</script>
