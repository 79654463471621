<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-card card">
          <div class="card-body">
            <div class="popupLoader" v-if="loading">
              <i class="far fa-spinner fa-spin"></i>
            </div>
            <div v-else class="show-iframe">
              <PointOfSale :item="item" style="width: 8cm; margin: 0px auto; background: white" />
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="$emit('close')">
                  <i class="far fa-times"></i>
                  {{ $t('close') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from 'axios'
import Vue from 'vue'
import { ref, defineEmits, defineProps } from 'vue'
import PointOfSale from '@/print/components/pointOfSales'

const item = ref({})
const loading = ref(true)

const getItem = () => {
  axios.get(Vue.prototype.$linkGenerator('/invoices/' + props.id)).then((response) => {
    item.value = response.data
    loading.value = false
  })
}

getItem()

const props = defineProps(['id'])
defineEmits('close')
</script>
