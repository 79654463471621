<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Incomes by Category')" />
        <div class="card-body">
          <div class="row">
            <searchInput
              class="col-md-3"
              :title="$t('Main category')"
              :plaseholder="$t('Choose category')"
              v-model="params.income_section_id"
              :show="'name'"
              :refrance="'id'"
              :value="params.income_section_id"
              :values="$database.incomeSections.filter((el) => el.income_section_id == 0)"
            />
            <searchInput
              v-if="params.income_section_id"
              class="col-md-3"
              :title="$t(SubCategory)"
              :plaseholder="$t('Choose category')"
              v-model="params.sup_income_section_id"
              :show="'name'"
              :refrance="'id'"
              :value="params.sup_income_section_id"
              :values="
                $database.incomeSections.filter(
                  (el) => el.income_section_id == params.income_section_id
                )
              "
            />

            <dateFilter class="col-md-3" />

            <searchInput
              class="col-md-3"
              :title="$t('User')"
              :plaseholder="$t('Choose user')"
              v-model="params.user_id"
              :show="'name'"
              :refrance="'id'"
              :value="params.user_id"
              :values="$database.users"
            />
            <searchInput
              class="col-md-3"
              :title="$t('Project')"
              :plaseholder="$t('Choose project')"
              :show="'name'"
              :refrance="'id'"
              v-model="params.project_id"
              :value="params.project_id"
              :values="$database.projects"
            />
            <searchInput
              class="col-md-3"
              :title="$t('Safe')"
              :plaseholder="$t('Choose safe')"
              :show="'name'"
              :refrance="'id'"
              v-model="params.safe_id"
              :value="params.safe_id"
              :values="$database.safes"
            />
            <searchInput
              class="col-md-3"
              :title="$t('Customer')"
              :plaseholder="$t('Choose customer')"
              v-model="params.customer_id"
              :show="'name'"
              :refrance="'id'"
              :value="params.customer_id"
              :values="$database.customers"
            />
            <searchInput
              class="col-md-3"
              :title="$t('Supplier')"
              :plaseholder="$t('Choose supplier')"
              v-model="params.supplier_id"
              :show="'name'"
              :refrance="'id'"
              :value="params.supplier_id"
              :values="$database.suppliers"
            />

            <actionButtons class="col-md-3" />
          </div>
        </div>
        <div id="reportShow">
          <reportHeader
            :title="$t('Incomes by category')"
            :details="[
              { title: 'التصنيف الأساسي', value: sectionName },
              { title: 'التصنيف الفرعي', value: supSectionName },
              { title: $t('Username'), value: userName },
              { title: 'الخزينة', value: safeName },
              { title: 'العميل', value: customerName },
              { title: 'المورد', value: supplierName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('there are no data at the moment')"
            :emptyTableSubText="$t('Try adding some items and try again')"
            :cloumns="[
              { column: 'name', title: $t('Category'), type: 'text', sort: true },
              {
                column: 'incomesCount',
                title: $t('Incomes number'),
                type: 'text',
                sort: true
              },
              {
                column: 'cost',
                title: $t('Price'),
                type: 'text',
                sort: true
              }
            ]"
            :footer="[
              { colspan: 1, value: $t('Total') },
              { colspan: 1, value: incomesCount },
              { colspan: 1, value: cost }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import dateFilter from '@/elements/reports/dateFilter.vue'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'

export default {
  data() {
    return {
      path: '/reports/incomes/incomeByCategory',
      items: [],
      loading: 1,

      incomesCount: 0,
      cost: 0,

      params: {
        income_section_id: '',
        customer_id: '',
        supplier_id: '',
        safe_id: '',
        user_id: '',
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'incomes by category',
        reportData: '[]'
      },

      sectionName: '',
      supSectionName: '',
      userName: '',
      safeName: '',
      customerName: '',
      supplierName: '',

      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.totalPages = 0
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        this.searchStartDate = this.params.startDate
        this.searchEndDate = this.params.endDate

        this.incomesCount = 0
        this.cost = 0

        this.items.forEach((item) => {
          item.incomesCount = item.incomes.length
          item.cost = 0

          item.incomes.forEach((income) => {
            item.cost += income.cost
          })
          this.cost += item.cost
          this.incomesCount += item.incomesCount

          item.cost = this.$moneyFormat(item.cost)
          item.incomesCount = item.incomesCount
        })

        this.cost = this.$moneyFormat(this.cost)
        this.incomesCount = this.incomesCount

        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].cost == 0) {
            this.items.splice(i, 1)
            i--
          }
        }
        this.loading = 0
      })

      var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
      if (customer) {
        this.customerName = customer.name
      } else {
        this.customerName = ''
      }

      var safe = this.$database.safes.find((el) => el.id == this.params.safe_id)
      if (safe) {
        this.safeName = safe.name
      } else {
        this.safeName = ''
      }

      var user = this.$database.users.find((el) => el.id == this.params.user_id)
      if (user) {
        this.userName = user.name
      } else {
        this.userName = ''
      }

      var supplier = this.$database.suppliers.find((el) => el.id == this.params.supplier_id)
      if (supplier) {
        this.supplierName = supplier.name
      } else {
        this.supplierName = ''
      }

      var section = this.$database.incomeSections.find(
        (el) => el.id == this.params.income_section_id
      )
      if (section) {
        this.sectionName = section.name
      } else {
        this.sectionName = ''
      }

      var supSection = this.$database.incomeSections.find(
        (el) => el.id == this.params.sup_income_section_id
      )
      if (section) {
        this.supSectionName = supSection.name
      } else {
        this.supSectionName = ''
      }
    }
  },
  watch: {
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.$updateDatabase(['incomeSections', 'users'])
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    dateFilter,
    searchInput,
    actionButtons
  }
}
</script>
