var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Store Transfers by invoice')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('from Store'),"plaseholder":_vm.$t('Choose store'),"show":'name',"refrance":'id',"value":_vm.params.from_store_id,"values":_vm.$database.stores},model:{value:(_vm.params.from_store_id),callback:function ($$v) {_vm.$set(_vm.params, "from_store_id", $$v)},expression:"params.from_store_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('To store'),"plaseholder":_vm.$t('Choose Store'),"show":'name',"refrance":'id',"value":_vm.params.to_store_id,"values":_vm.$database.stores},model:{value:(_vm.params.to_store_id),callback:function ($$v) {_vm.$set(_vm.params, "to_store_id", $$v)},expression:"params.to_store_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('user'),"plaseholder":_vm.$t('Choose user'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.$database.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Store transfers by invoice'),"details":[
            { title: 'من مخزن', value: _vm.fromStoreName },
            { title: 'إلى مخزن', value: _vm.toStoreName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'code',
              link: '/storesDesposits',
              title: _vm.$t('Code'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'date',
              title: _vm.$t('Date'),
              type: 'text',
              sort: true,
              supData: true
            },

            {
              column: 'from_store_name',
              title: _vm.$t('From store'),
              type: 'text'
            },
            {
              column: 'to_store_name',
              title: _vm.$t('To store '),
              type: 'text'
            },

            {
              column: 'user',
              title: _vm.$t('User'),
              type: 'text',
              sort: true,
              supData: true
            }
          ],"footer":[
            { colspan: 4, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.invoicesCount, supData: true }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }