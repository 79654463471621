<template>
  <div v-if="!loading">
    <div
      class="inv-design"
      :class="$option.stores_orders_print_type == 3 ? 'inv-design-5cm' : ''"
      v-if="$option.stores_orders_print_type == 1 || $option.stores_orders_print_type == 3"
    >
      <img :src="logo" class="img-fluid mb-4 sm-inv-img" v-if="$option.image != null" />
      <div class="text-center">
        <p class="f-14">{{ $option.name }}</p>
        <p class="f-12">{{ $option.address1 }}</p>
        <p class="f-12">{{ item.code }}</p>
        <div style="display: flex">
          <div style="width: 4cm; text-align: right">
            <p class="f-12">
              التاريخ : <strong>{{ $dateFormat(item.date) }}</strong>
            </p>
            <p class="f-12">
              الوقت : <strong>{{ item.time }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table my-4">
          <thead>
            <tr>
              <th class="px-0 bg-transparent border-top-0 text-right">
                <span class="h6">المنتج</span>
              </th>
              <th class="px-0 bg-transparent border-top-0 text-right">
                <span class="h6">الكمية</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="qItem in item.quotationItems" :key="qItem.id">
              <td class="px-0">
                {{ qItem.product_name }}
              </td>
              <td class="px-0">
                {{ qItem.quantity }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="px-0">
                <strong>الإجمالي </strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{
                    parseFloat(
                      item.quotationItems.reduce(
                        (sum, currentItem) => sum + parseFloat(currentItem.quantity),
                        0
                      )
                    ).toFixed(1)
                  }}
                  قطعة
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
        <p
          class="text-muted mb-0"
          v-html="$option.invoice_footer != null ? $option.invoice_footer : ''"
        ></p>
      </div>
    </div>
    <div class="row invoice-show hide-w-8" v-if="$option.stores_orders_print_type == 0">
      <div class="col-12 text-center">
        <img
          :src="logo"
          class="img-fluid mb-4"
          style="max-width: 4.5rem"
          v-if="$option.image != null"
        />
        <h2 class="mb-2">
          {{ $option.name }}
        </h2>
        <p class="text-muted">
          {{ item.code }}
        </p>
        <p class="text-muted mb-2">
          <strong>المخزن:</strong> {{ item.store ? item.store.name : '--' }}
        </p>

        <p class="text-muted mb-2">
          <strong>{{ $t('Date') }}:</strong>
          {{ $dateFormat(item.date) }}
        </p>
      </div>
    </div>
    <div class="row invoice-show hide-w-8" v-if="$option.stores_orders_print_type == 0">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table my-w">
            <thead>
              <tr>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('ProductItem') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('description') }}</span>
                </th>
                <th class="px-0 bg-transparent border-top-0">
                  <span class="h6">{{ $t('Quantity') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="qItem in item.quotationItems" :key="qItem.id">
                <td class="px-0">
                  {{ qItem.product_name }}
                </td>
                <td class="px-0">
                  {{ qItem.description }}
                </td>
                <td class="px-0">{{ qItem.quantity }} {{ qItem.unit ? qItem.unit.unit : '' }}</td>
              </tr>
              <tr>
                <td colspan="2" class="px-0 border-top border-top-2">
                  <strong>اجمالي الكمية</strong>
                </td>
                <td colspan="1" class="px-0 border-top border-top-2">
                  <span class="h3">
                    {{
                      parseFloat(
                        item.quotationItems.reduce(
                          (sum, currentItem) => sum + parseFloat(currentItem.quantity),
                          0
                        )
                      ).toFixed(1)
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-100" v-if="item.description != null">
          <hr class="my-3" />
          <h4 class="text-uppercase">{{ $t('notes') }}</h4>
          <p class="text-muted mb-0">{{ item.description }}</p>
        </div>
      </div>
      <div class="w-100 inv-footer" v-if="$option.stores_deposits_print_footer != null">
        <hr class="my-3" />
        <p
          class="text-muted mb-0"
          v-html="
            $option.stores_deposits_print_footer != null ? $option.stores_deposits_print_footer : ''
          "
        ></p>
      </div>
      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in $option.stores_deposits_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: localStorage.getItem('logo')
    }
  },
  props: {
    item: {},
    loading: null
  }
}
</script>
