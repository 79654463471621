var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Discounts'),"description":_vm.$t('From here you can control your Discounts reports')}})],1),(
      (_vm.$user.role.available_reports.find((el) => el.id == 29 || el.id == 30) || _vm.$user.admin) &&
      (_vm.$site.salesDiscounts_allow || _vm.$site.purchaseDiscounts_allow) &&
      (_vm.$user.admin || _vm.$user.role.sales_discounts_show || _vm.$user.role.purchase_discounts_show)
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('Discounts'),"icon":'fa-badge-percent',"reports":[
      {
        name: _vm.$t('Record Sales Discounts'),
        link: './discountsHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 29) || _vm.$user.admin
      },
      {
        name: _vm.$t('Record Purchase Discounts'),
        link: '/purchaseDiscountsHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 30) || _vm.$user.admin
      }
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }