var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Safe withdrawal register')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('user'),"plaseholder":_vm.$t('Choose user'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.$database.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("نوع الحالة")]),_c('select',{staticClass:"form-control",attrs:{"type":"text"}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('orders.situation')))]),_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('orders.all')))]),_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t('invoices.draft')))]),_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t('invoices.approved')))])])])]),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Safe'),"plaseholder":_vm.$t('Choose safe'),"show":'name',"refrance":'id',"value":_vm.params.safe_id,"values":_vm.$database.safes},model:{value:(_vm.params.safe_id),callback:function ($$v) {_vm.$set(_vm.params, "safe_id", $$v)},expression:"params.safe_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Safe withdrawals register'),"details":[
            { title: _vm.$t('Username'), value: _vm.userName },
            { title: ' الخزينة', value: _vm.safeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'code',
              link: '/purchasePayments',
              title: _vm.$t('Code'),
              type: 'reportMainLink',
              sort: true
            },
            { column: 'date', title: _vm.$t('Date'), type: 'text', sort: true },
            { column: 'user', title: _vm.$t('User'), type: 'text', sort: true },
            {
              column: 'safe_id',
              title: _vm.$t('Safe'),
              element: 'safe',
              type: 'link',
              to: 'safe',
              sort: true,
              link: true
            },
            { column: 'cost', title: _vm.$t('Price'), type: 'text', sort: true }
          ],"footer":[
            { colspan: 4, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.cost }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }