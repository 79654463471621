<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="sm-stop-edit" v-if="loading"></div>
        <div class="modal-card card">
          <div class="card-header" style="background: none">
            <h4 class="card-header-title">دفع شيك</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <connectionInput
                col="col-12"
                title="الخزينة "
                v-model="safe_id"
                :values="$database.safes.filter((el) => el.id != $parent.not_safe_id)"
              />
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="toggleOpen()">
                  <i class="far fa-times"></i>
                  {{ $t('Close') }}
                </button>
                <button @click="changeStatus()" class="btn btn-dark" v-if="!loading">
                  <i class="far fa-check"></i>
                  {{ $t('Pay') }}
                </button>
                <button class="btn btn-dark" v-if="loading">
                  {{ $t('Loading') }}<span class="loading-s1 sm-j">.</span
                  ><span class="loading-s2 sm-j">.</span><span class="loading-s3 sm-j">.</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import connectionInput from '@/elements/add/form/connectionInput.vue'
import axios from 'axios'
export default {
  data() {
    return {
      loading: false,
      safe_id: null
    }
  },
  methods: {
    close() {
      this.$parent.pay_sales_cheque_id = null
    },
    changeStatus() {
      if (this.safe_id) {
        this.doPaySalesCheque(this.safe_id)
        this.loading = true
      }
    },
    doPaySalesCheque(id) {
      axios
        .post(this.$linkGenerator('/salescheques/update/stat'), {
          safe_id: id,
          id: this.chequeId
        })
        .then(() => {
          this.toggleOpen(this.chequeId)
          // this.getItems()
        })
    }
  },
  components: {
    connectionInput
  },
  props: ['toggleOpen', 'chequeId']
}
</script>
<!-- <a
@click="$parent.paySalesCheque(item.id)"
class="dropdown-item"
v-if="option.name == 'paySalesCheque' && item.stat == 0"
>
<i class="far fa-money-bill"></i>
دفع الشيك
</a> -->
<!-- <a
@click="$parent.payPurchaseCheque(item.id)"
class="dropdown-item"
v-if="option.name == 'payPurchaseCheque' && item.stat == 0"
>
<i class="far fa-money-bill"></i>
دفع الشيك
</a> -->
