<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered big-model">
      <div class="modal-content">
        <div class="modal-card card">
          <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
              {{ $t('Show contact') }}
            </h4>
            <!-- Close -->
            <button type="button" class="close" @click="closeShow()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Name') }}
                  </label>
                  <div class="col-md-6">: {{ contact.name || '--' }}</div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Occupation') }}
                  </label>
                  <div class="col-md-6">: {{ contact.job || '--' }}</div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Email') }}
                  </label>
                  <div class="col-md-6">: {{ contact.email || '--' }}</div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Telephone') }}
                  </label>
                  <div class="col-md-6">: {{ contact.telephone || '--' }}</div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Phone') }}
                  </label>
                  <div class="col-md-6">: {{ contact.mobile || '--' }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Linkedin') }}
                  </label>
                  <div class="col-md-6">: {{ contact.linkedin || '--' }}</div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Facebook') }}
                  </label>
                  <div class="col-md-6">: {{ contact.facebook || '--' }}</div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('Twitter') }}
                  </label>
                  <div class="col-md-6">: {{ contact.twitter || '--' }}</div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4">
                    {{ $t('GooglePlus') }}
                  </label>
                  <div class="col-md-6">: {{ contact.google_plus || '--' }}</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                {{ $t('Note') }}
              </label>
              <div class="lead">
                {{ contact.note || '--' }}
              </div>
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="closeShow()">
                  <i class="far fa-times"></i>
                  {{ $t('Close') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      contact: {}
    }
  },
  mounted() {
    this.getContact()
  },
  methods: {
    getContact() {
      axios
        .get(this.$linkGenerator('/contacts/' + this.$parent.contacts[this.$parent.show].id))
        .then((response) => {
          this.contact = response.data.contact
        })
    },
    closeShow() {
      this.$parent.show = null
      this.$parent.$parent.$parent.model = 0
    }
  }
}
</script>
