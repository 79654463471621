<template>
  <div class="form-group row col-md-12">
    <!-- Label -->
    <label class="col-md-2" :for="inputId">{{ title }}</label>
    <!-- Input -->
    <div class="col-md-95">
      <textarea
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.length }"
        @input="$emit('input', $event.target.value)"
        :value="value"
        :disabled="disabled"
        :id="inputId"
      ></textarea>
      <div class="invalid-feedback">
        <ul class="list-unstyled">
          <li v-for="(error, index) in errors" :key="index">{{ $t(error) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputId: Math.random().toString(36).substring(7)
    }
  },
  props: ['title', 'disabled', 'errors', 'value']
}
</script>
