import { Buffer } from 'buffer'
import Vue from 'vue'

const getTlvForValue = (tagNum, tagValue) => {
  const tagBuf = Buffer.from([tagNum], 'utf8')
  const tagValueBuf = Buffer.from(tagValue, 'utf8')
  const tagValueLenBuf = Buffer.from([tagValueBuf.length], 'utf8')
  const bufArray = [tagBuf, tagValueLenBuf, tagValueBuf]
  return Buffer.concat(bufArray)
}

const qrCodeGenerator = (date, total, Tax) => {
  // Decode data
  const sellerNameBuf = getTlvForValue('1', Vue.prototype.$option.zatca.organization_name)
  const vatRegistrationNameBuf = getTlvForValue('2', Vue.prototype.$option.zatca.vat_number)
  const timeBuf = getTlvForValue('3', date)
  const totalInvoiceBuf = getTlvForValue('4', total)
  const taxAmountBuf = getTlvForValue('5', Tax)
  const tagBufArray = [
    sellerNameBuf,
    vatRegistrationNameBuf,
    timeBuf,
    totalInvoiceBuf,
    taxAmountBuf
  ]
  // Get base 64
  const qrCodeBuf = Buffer.concat(tagBufArray)
  const qrCodeB64 = qrCodeBuf.toString('base64')
  return qrCodeB64
}

export default qrCodeGenerator
