<template>
  <div class="form-group row offset">
    <!-- Label -->
    <label class="col-md-4">{{ title }}</label>
    <!-- Input -->
    <div class="col-md-7 b-0">
      <div class="sup-file-uploader">
        <input type="file" id="file" ref="file" @change="submitFile()" v-if="!uploadLoader" />
        <input
          class="form-control"
          :value="value"
          :class="{ 'is-invalid': errors.length }"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-7">
      <div class="invalid-feedback d-block">
        <ul class="list-unstyled">
          <li v-for="(error, index) in errors" :key="index">{{ $t(error) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      uploadLoader: false
    }
  },
  methods: {
    submitFile() {
      const file = this.$refs.file.files[0]
      if (file.type.split('/')[0] == 'image' || file.type == 'application/pdf') {
        this.$emit('input', '')
        this.uploadLoader = true
        let formData = new FormData()
        formData.append('file', file)
        axios
          .post(this.$linkGenerator('/files/files'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.$emit('input', response.data)
            this.uploadLoader = false
          })
      }
    }
  },
  props: ['title', 'disabled', 'errors', 'value']
}
</script>
