var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v(_vm._s(_vm.$t('duration')))]),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('Choose Time And Date'),"button-now-translation":_vm.$t('now'),"format":"YYYY-MM-DD","formated":"YYYY-MM-DD","color":"#631263","button-color":"#631263","locale":"ar","range":true,"onlyDate":true,"custom-shortcuts":[
        {
          key: 'thisWeek',
          label: _vm.$t('this Week'),
          value: 'isoWeek'
        },
        {
          key: 'lastWeek',
          label: _vm.$t('the Last Week'),
          value: '-isoWeek'
        },
        {
          key: 'last7Days',
          label: _vm.$t('the Last Days'),
          value: 7
        },
        {
          key: 'last30Days',
          label: _vm.$t('the Last 30Days'),
          value: 30
        },
        {
          key: 'this Month',
          label: _vm.$t('this Month'),
          value: 'month'
        },
        {
          key: 'last Month',
          label: _vm.$t('previous Month'),
          value: '-month'
        },
        {
          key: 'thisYear',
          label: _vm.$t('this Year'),
          value: 'year'
        },
        {
          key: 'lastYear',
          label: _vm.$t('last Year'),
          value: '-year'
        }
      ]},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }