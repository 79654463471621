<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :icon="'treasure-chest'"
        :title="item.name"
        :stat="item.stat"
        :statText="item.stat ? $t('Active') : $t('Inactive')"
        :subData="[
          {
            title: $t('Current balance'),
            value: this.$moneyFormat(item.balance)
          }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('safes_edit')
          },
          {
            title: $t('Treasury statement'),
            icon: 'file-chart-pie',
            link: '/safesAccounts?safe_id=' + $route.params.id,
            role: $checkRole('reports_show')
          }
        ]"
      />
      <showBoxs
        class="col-12"
        :boxs="[
          {
            icon: 'dollar-sign',
            title: $t('Current balance'),
            value: $moneyFormat(item.balance),
            class: 'col-md-3'
          },
          {
            icon: 'credit-card-front',
            title: $t('Invoices count'),
            value: item.salesPaymentsCount,
            class: 'col-md-3'
          },
          {
            icon: 'credit-card-front',
            title: $t('Quotations count'),
            value: item.purchasePaymentsCount,
            class: 'col-md-3'
          },
          {
            icon: 'usd-square',
            title: $t('ordersCount'),
            value: item.expensesCount,
            class: 'col-md-3'
          }
        ]"
      />
      <showCard
        class="col-12"
        :title="$t('Store address')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Address'),
              value: item.address1
            },
            {
              icon: 'location-arrow',
              title: $t('The side'),
              value: item.entity
            },
            {
              icon: 'map-marked',
              title: $t('Zip code'),
              value: item.zip
            },
            {
              icon: 'dollar-sign',
              title: $t('Balance'),
              value: this.$moneyFormat(item.balance)
            }
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('The sequel'),
              value: item.address2
            },
            { icon: 'city', title: $t('City'), value: item.city },
            {
              icon: 'globe-americas',
              title: $t('State'),
              value: item.country
            },
            {
              icon: 'dollar-sign',
              title: $t('Opening balance'),
              value: this.$moneyFormat(item.opening_balance)
            }
          ]
        }"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'
import showBoxs from '@/elements/show/showBoxs.vue'

export default {
  data() {
    return {
      path: '/safes',
      loaded: false,
      item: {}
    }
  },
  mounted() {
    this.getItem()
  },
  methods: {
    getItem() {
      var item = this.$database.safes.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.loaded = true
      })
    }
  },
  components: {
    showHeader,
    showCard,
    showBoxs
  }
}
</script>
