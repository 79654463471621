var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'user',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        {
          title: _vm.$t('tax Identification Number'),
          value: _vm.item.tax_number
        }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('customers_edit')
        },
        {
          title: _vm.$t('Comprehensive report'),
          icon: 'file-chart-pie',
          link: '/customersDetailsAccounts?customer_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('reports_show')
        },
        {
          title: _vm.$t('Add a batch'),
          icon: 'dollar-sign',
          link: '/salesPayments/create?customer_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('sales_payments_add')
        },
        {
          title: _vm.$t('AddABid'),
          icon: 'calculator',
          link: '/quotations/create?customer_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('quotations_add')
        },
        {
          title: _vm.$t('AddASalesBill'),
          icon: 'file-invoice-dollar',
          link: '/invoices/create?customer_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('invoices_add')
        },
        {
          title: _vm.$t('Add a request'),
          icon: 'briefcase',
          link: '/orders/create?customer_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('orders_add')
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Background'),"cloumns":{
        firstCloum: [
          {
            icon: 'user',
            title: _vm.$t('Customer name'),
            value: _vm.item.name
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Balance'),
            value: _vm.$moneyFormat(_vm.item.balance)
          }
        ],
        scondCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Opening balance'),
            value: _vm.$moneyFormat(_vm.item.opening_balance)
          },
          {
            icon: 'toggle-on',
            title: _vm.$t('CustomerStatus'),
            value: _vm.item.stat == 1 ? _vm.$t('allerts.effective') : _vm.$t('allerts.Ineffective')
          }
        ]
      }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('BillingAddress'),"cloumns":{
        firstCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('Address'),
            value: _vm.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('The side'),
            value: _vm.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('Zip code'),
            value: _vm.item.zip
          }
        ],
        scondCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('The sequel'),
            value: _vm.item.address2
          },
          {
            icon: 'city',
            title: _vm.$t('City'),
            value: _vm.item.city
          },
          {
            icon: 'globe-americas',
            title: _vm.$t('State'),
            value: _vm.item.country
          }
        ]
      }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Communication Information'),"cloumns":{
        firstCloum: [
          {
            icon: 'phone',
            title: _vm.$t('Mobile number'),
            value: _vm.item.mobile2 ? _vm.item.mobile + ' - ' + _vm.item.mobile2 : _vm.item.mobile
          }
        ],
        scondCloum: [
          {
            icon: 'at',
            title: _vm.$t('Email'),
            value: _vm.item.email
          }
        ]
      }}}),_c('notes',{staticClass:"col-12 col-xl-7"}),_c('contacts',{staticClass:"col-12 col-lg-5"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }