<template>
  <div class="print-model">
    <div id="printMe" ref="printMe" v-if="!loading">
      <PointOfSale :item="item" />
    </div>
  </div>
</template>
<script setup>
import Vue from 'vue'
import { ref, defineProps, defineEmits } from 'vue'
import PointOfSale from './components/pointOfSales'

// Define params
const loading = ref(true)

// Print invoice
const printItem = () => {
  setTimeout(function () {
    // Prepare content
    const html = document.getElementById('printMe').innerHTML
    const css =
      '<meta charset="utf-8" /><style>' +
      Vue.prototype.$printFont +
      invoiceCss +
      Vue.prototype.$option.invoice_css +
      '</style>'
    const content = '<head>' + css + '</head><body>' + html + '</body>'
    if (typeof ipcRenderer !== 'undefined') {
      const printDetails = {
        options: {
          preview: false,
          copies: 1,
          printerName: 'default',
          silent: true
        },
        data: content
      }
      ipcRenderer.send('print', JSON.stringify(printDetails))
      document.getElementById('printMe').remove()
    } else {
      const iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      iframe.contentWindow.document.open()
      iframe.classList.add('printIframe')
      iframe.contentWindow.document.write(content)
      iframe.contentWindow.document.close()
      iframe.onload = function () {
        iframe.focus()
        iframe.contentWindow.print()
        // iframe.remove()
      }
    }
    emits('closePrint')
  }, 20)
}

// Print item
loading.value = false
printItem()

// Define props
const props = defineProps(['item'])
const emits = defineEmits(['closePrint'])

const invoiceCss = `
  body{
    -webkit-print-color-adjust:exact!important;
  }
  @page {
    size: 2.8in 11in;
    margin-top: 0cm;
    margin-left: 0cm;
    margin-right: 0cm;
  }
  body {
    font-family:'Cerebri Sans',Tajawal,sans-serif;
    background-color: white;
    direction: rtl;
    text-align: right;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    width: 100%;
  }
  .invoice-container {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  .logo {
    width: 4cm;
    margin: 4px auto;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  .invoice-number {
    font-weight: bold;
    margin-bottom: 6px;
    font-size: 12px;
  }
  .store-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px;
    color: #333;
  }
  .store-address {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    color: #333;
  }
  .date-info {
    text-align: start;
    font-size: 11px;
    font-weight: 500;
    color: #333;
  }
  .register-number-info {
    text-align: start;
    font-size: 11px;
    font-weight: 500;
    color: #333;
  }
  .details-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
    border-bottom: 1px dashed #ddd;
  }
  .details-table th, .details-table td {
    padding: 8px 3px;
    font-size: 9px;
  }
  .details-table th {
    border-top: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
    font-weight: bold;
  }
  .summary {
    text-align: right;
    margin-top: 10px;
    font-size: 12px;
  }
  .summary div {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
  }
  .summary .total {
    font-weight: bold;
    color: #333;
  }
  .footer {
    margin-top: 30px;
    font-size: 10px;
    color: #666;
  }
  .qr-code {
    margin-top: 10px;
  }`
</script>
