<template>
  <div>
    <div class="pos-sidebar-card card" :style="style">
      <div class="card-header">
        <!-- Title -->
        <h1 class="pos-sidebar-title">
          {{ $t('Current orders') }}
        </h1>
        <!-- Clear button -->
        <button class="pos-sidebar-btn" @click="$emit('resetPointOfSale')">
          {{ $t('Clear all') }}
        </button>
        <!-- Options menu -->
        <div class="dropdown" :class="{ show: showMenu }" ref="elementRef">
          <button
            class="pos-sidebar-options-btn"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="showMenu"
            @click="toggleMenu()"
          >
            <i class="fas fa-cog"></i>
          </button>

          <div
            class="dropdown-menu"
            :class="{ show: showMenu }"
            aria-labelledby="dropdownMenuButton"
          >
            <a class="dropdown-item" @click="refreshPos">
              <i class="far fa-redo ml-3"></i>
              {{ $t('Refresh') }}
            </a>
            <router-link
              v-if="$checkRole('reports_show')"
              class="dropdown-item"
              :to="
                '/salesByItem?session_number=' +
                options.session_number +
                '&point_of_sale_id=' +
                options.id +
                '&startDate='
              "
            >
              <i class="far fa-boxes ml-3"></i>
              {{ $t('Products report') }}
            </router-link>
            <router-link
              v-if="$checkRole('reports_show')"
              class="dropdown-item"
              :to="
                '/salesByInvoice?session_number=' +
                options.session_number +
                '&point_of_sale_id=' +
                options.id +
                '&startDate='
              "
            >
              <i class="far fa-file-invoice-dollar ml-3"></i>
              {{ $t('Invoices report') }}
            </router-link>
            <router-link
              v-if="$checkRole('reports_show')"
              class="dropdown-item"
              :to="
                '/expenseHistory?session_number=' +
                options.session_number +
                '&point_of_sale_id=' +
                options.id +
                '&startDate='
              "
              ><i class="far fa-usd-square ml-3"></i>
              {{ $t('Expenses report') }}
            </router-link>
            <router-link
              v-if="$checkRole('reports_show')"
              class="dropdown-item"
              :to="
                '/paymentHistory?session_number=' +
                options.session_number +
                '&point_of_sale_id=' +
                options.id +
                '&startDate='
              "
            >
              <i class="far fa-credit-card-front ml-3"></i>
              {{ $t('Payments report') }}
            </router-link>
            <router-link
              v-if="$checkRole('reports_show')"
              class="dropdown-item"
              :to="
                '/pointOfSalesOverview?session_number=' +
                options.session_number +
                '&point_of_sale_id=' +
                options.id +
                '&startDate='
              "
            >
              <i class="far fa-file-chart-line ml-3"></i>
              {{ $t('Overview report') }}
            </router-link>
          </div>
        </div>
      </div>
      <vue-custom-scrollbar
        class="card-body"
        :settings="{
          suppressScrollY: false,
          suppressScrollX: true
        }"
      >
        <div class="pos-sidebar-data" v-if="options.marketer_allow">
          <div class="pos-sidebar-data-title">
            {{ $t('marketer') }}
          </div>
          <div class="pos-sidebar-data-body">
            <div class="add-input table-add-input" v-if="options.marketer_allow">
              <select v-model="marketer" class="">
                <option value="0">
                  {{ $t('marketer') }}
                </option>
                <optgroup :label="$t('marketers')">
                  <option
                    v-for="(marketer, index) in $database.marketers"
                    :value="marketer.id"
                    :key="index"
                  >
                    {{ marketer.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>

        <div class="pos-sidebar-data" v-if="options.show_balance">
          <div class="pos-sidebar-data-title">
            {{ $t('Machine balance') }}
          </div>
          <div class="pos-sidebar-data-body">
            {{ $moneyFormat($database.safes.find((el) => el.id == options.safe_id).balance) }}
          </div>
        </div>

        <div class="pos-sidebar-data" v-if="options.change_priceList">
          <div class="pos-sidebar-data-title">
            {{ $t('List of prices') }}
          </div>
          <div class="pos-sidebar-data-body">
            <div class="add-input table-add-input">
              <select
                v-model="priceList"
                :disabled="!options.change_priceList"
                class="form-control m-w-50"
              >
                <option value="0">
                  {{ $t('Choose price list') }}
                </option>
                <optgroup :label="$t('Price lists')">
                  <option
                    v-for="(priceList, index) in $database.priceLists"
                    :value="priceList.id"
                    :key="index"
                  >
                    {{ priceList.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>

        <div class="pos-sidebar-data" v-if="options.pay_methods_allow">
          <div class="pos-sidebar-data-title">
            {{ $t('Payment method') }}
          </div>
          <div class="pos-sidebar-data-body">
            <div class="add-input table-add-input">
              <select v-model="$parent.payment_method">
                <option value="">
                  {{ $t('Choose payment method') }}
                </option>
                <option
                  v-for="(paymentMethod, index) in options.payment_methods"
                  :key="index"
                  :value="paymentMethod.safe_id"
                >
                  {{ paymentMethod.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <hr />
        <div class="pos-sidebar-data">
          <div class="pos-sidebar-data-title">
            {{ $t('Total') }}
          </div>
          <div class="pos-sidebar-data-body">
            {{ $moneyFormat(item.subtotal) }}
          </div>
        </div>
        <div class="pos-sidebar-data" v-if="options.discount || options.change_discount">
          <div class="pos-sidebar-data-title">
            {{ $t('Discount rate') }}
          </div>
          <div class="pos-sidebar-data-body">
            <div class="d-flex">
              <input
                type="number"
                :max="options.max_discount"
                placeholder="0"
                v-model="item.totalDiscount"
                @keyup="$emit('calculateTotal')"
                :disabled="!options.change_discount"
              />
              <span>%</span>
            </div>
          </div>
        </div>

        <div
          class="pos-sidebar-data"
          v-if="options.discount || options.change_discount || options.change_product_discount"
        >
          <div class="pos-sidebar-data-title">
            {{ $t('Discount') }}
          </div>
          <div class="pos-sidebar-data-body">- {{ $moneyFormat(item.discount) }}</div>
        </div>

        <div class="pos-sidebar-data">
          <div class="pos-sidebar-data-title">
            <input v-model="editText" :placeholder="$t('add')" />
          </div>
          <div class="pos-sidebar-data-body">
            <input
              type="number"
              placeholder="0"
              v-model="edit"
              min="0"
              @keyup="$emit('calculateTotal')"
            />
          </div>
        </div>
        <div class="pos-sidebar-data" v-if="options.tax_id">
          <div class="pos-sidebar-data-title">
            {{ $t('Tax') }}
          </div>
          <div class="pos-sidebar-data-body">
            {{ $moneyFormat(item.tax) }}
          </div>
        </div>
        <div class="pos-sidebar-data">
          <div class="pos-sidebar-data-total">
            {{ $t('Total') }}
          </div>
          <div class="pos-sidebar-data-body" style="color: #d74848">
            {{ $moneyFormat(item.total) }}
          </div>
        </div>

        <div class="pos-sidebar-data" v-if="options.rest_allow">
          <div class="pos-sidebar-data-title">
            {{ $t('Rest') }}
          </div>
          <div class="pos-sidebar-data-body">
            {{ $moneyFormat(item.rest) }}
          </div>
        </div>

        <label class="pos-notes-lable">{{ $t('notes') }}</label>
        <textarea
          :placeholder="$t('Add notes')"
          v-model="notes"
          class="form-control pos-notes-input"
        >
        </textarea>
        <div class="d-flex">
          <button
            v-if="options.show_rest || options.change_customer"
            class="btn btn-primary pos-side-checkout w-100"
            :disabled="item.invoiceItems.length == 0"
            @click="$emit('showCash')"
          >
            <i class="fas fa-credit-card-front"></i>
            {{ $t('Pay off') }}
          </button>
          <button
            v-else
            class="btn btn-primary pos-side-checkout w-100"
            :disabled="item.invoiceItems.length == 0"
            @click="checkOut()"
          >
            <i class="fas fa-credit-card-front"></i>
            {{ $t('Pay off') }}
          </button>
        </div>
        <div style="height: 40px"></div>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, computed, watch } from 'vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import { useClickOutside } from '@/helpers/useClickOutside'

// Click outside logic
const { elementRef, isOutside } = useClickOutside()
watch(isOutside, (value, oldValue) => {
  if (value && oldValue != value) removeMenu()
})

// Menu logic
const showMenu = ref(false)
const toggleMenu = () => (showMenu.value = !showMenu.value)
const removeMenu = () => (showMenu.value = false)

// Refresh POS
const refreshPos = () => {
  emits('refreshPos')
  removeMenu()
}

// Watch window changes
const style = ref('top: 80px;height: calc(100% - 100px);')
window.addEventListener('scroll', function () {
  if (this.scrollY <= 50) {
    style.value =
      'top: ' + (63 - this.scrollY) + 'px;height: calc(100% - ' + (68 - this.scrollY) + 'px);'
  } else {
    style.value = 'top: 10px;height: calc(100% - 30px);'
  }
})

// Computed inputs
const notes = computed({
  get: () => props.item.notes,
  set: (value) => emits('updateItem', { ...props.item, notes: value })
})
const edit = computed({
  get: () => props.item.edit,
  set: (value) => emits('updateItem', { ...props.item, edit: value })
})
const editText = computed({
  get: () => props.item.edit_text,
  set: (value) => emits('updateItem', { ...props.item, edit_text: value })
})
const priceList = computed({
  get: () => props.item.priceList_id,
  set: (value) => emits('updateItem', { ...props.item, priceList_id: value })
})
const marketer = computed({
  get: () => props.item.marketer_id,
  set: (value) => emits('updateItem', { ...props.item, marketer_id: value })
})

// Define props
const props = defineProps(['options', 'item'])
const emits = defineEmits(['updateItem', 'refreshPos'])
</script>
