<template>
  <div class="form-group row">
    <label class="col-4" :for="inputId">{{ title }}</label>
    <div class="col-7">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          :checked="value === 1 || value == true"
          :class="{ 'is-invalid': errors.length }"
          @change="changeValue"
          :disabled="disabled"
          :id="inputId"
        />
        <label :for="inputId" class="custom-control-label"></label>
        <div class="invalid-feedback">
          <ul class="list-unstyled">
            <li v-for="(error, index) in errors" :key="index">{{ $t(error) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputId: Math.random().toString(36).substring(7)
    }
  },
  methods: {
    changeValue() {
      if (this.value == 1 || this.value == true) {
        this.$emit('input', 0)
      } else if (this.value == 0 || this.value == false) {
        this.$emit('input', 1)
      }
    }
  },
  props: ['type', 'title', 'disabled', 'errors', 'value']
}
</script>
