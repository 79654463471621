<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Add purchase discount')"
          :description="$t('From here you can create a new purchase discount')"
          v-if="$route.name == 'purchaseDiscountsCreate'"
        />
        <addHeader
          :title="$t('Purchase discount adjustment')"
          :description="$t('From here you can modify the purchase discount information')"
          v-if="$route.name == 'purchaseDiscountsEdit'"
        />
        <div class="mb-4 row">
          <connectionInput
            :title="$t('Discount number')"
            :group="$t('Groups')"
            v-model="item.invoice_group"
            :disabled="$checkRole('purchase_discounts_edit_item') ? false : true"
            :hasErorr="errors.invoice_group"
            :error="$t('This field is required')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'purchaseDiscountsCreate'"
          />
          <formInput
            :title="$t('Discount code')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'purchaseDiscountsEdit'"
            maxlength="255"
          />
          <subFileInput
            :title="$t('Invoice file')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
            :hasErorr="errors.file"
            :error="$t('Choose a suitable file')"
          />
          <formInput :title="$t('Reference')" v-model="item.refrance" maxlength="255" />

          <selectInput
            :title="$t('Discount type')"
            v-model="item.client_type"
            :disabled="$route.name == 'purchaseDiscountsEdit' ? true : false"
            :values="[
              { name: $t('Customer discount'), value: 0 },
              { name: $t('Supplier discount'), value: 1 },
              { name: $t('Partner discount'), value: 2 }
            ]"
          />

          <searchGroupInput
            v-if="item.client_type == 0"
            :type="'customer'"
            :values="$database.customers.filter((el) => el.stat == 1)"
            :inputs="$option.purchase_discounts_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 1"
            :type="'supplier'"
            :values="$database.suppliers.filter((el) => el.stat == 1)"
            :inputs="$option.purchase_discounts_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 2"
            :type="'partner'"
            :values="$database.partners.filter((el) => el.stat == 1)"
            :inputs="$option.purchase_discounts_customer_data"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{ $t('Date') }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('Choose time and date')"
                  :button-now-translation="$t('Now')"
                  v-model="item.date"
                  :disabled="$checkRole('purchase_discounts_edit_item') ? false : true"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <monyInput
            :title="$t('Purchase discount amount')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('The amount received must be greater than')"
          />

          <searchGroupInput
            :type="'project'"
            :values="$database.projects"
            :inputs="[{ title: $t('Project'), show: 'name' }]"
            v-if="$site.projects_allow"
          />

          <connectionInput
            :disabled="item.project_id == 0"
            :title="$t('Project output')"
            :group="$t('Project outputs')"
            v-model="item.project_item_id"
            :values="$database.projectItems.filter((el) => el.project_id == item.project_id)"
            v-if="$site.projects_allow"
          />

          <selectInput
            :title="$t('Situation')"
            v-model="item.status"
            :values="[
              { name: $t('Draft'), value: 0 },
              { name: $t('Approved'), value: 1 }
            ]"
          />

          <dvider :title="$t('Discount notes')" :description="$t('Discount notes details')" />

          <formTextarea :title="$t('That about')" v-model="item.description" />
          <formTextarea :title="$t('Note')" v-model="item.notes" />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'purchaseDiscountsCreate' && !$parent.stopEdit"
          >
            {{ $t('Add discount operation') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'purchaseDiscountsEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit purchase discount') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
    <!-- / .row -->
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import subFileInput from '@/elements/add/form/subFileInput.vue'

import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  data() {
    return {
      path: '/purchaseDiscounts',
      item: {
        invoice_group: this.$option.purchase_discount_invoice_group_id,
        customer_id: 0,
        supplier_id: 0,
        partner_id: 0,
        project_id: 0,
        project: {},
        project_item_id: 0,
        customer: {
          name: '',
          mobile: '',
          address1: '',
          balance: 0
        },
        supplier: {
          name: '',
          mobile: '',
          address1: '',
          balance: 0
        },
        partner: {
          name: '',
          mobile: '',
          address1: '',
          balance: 0
        },
        client_type: 1,
        date: '',
        bank_tax: 0,
        status: 1
      },
      model: false,
      errors: {
        name: false,
        cost: false,
        invoice_group: false
      },
      print: 1
    }
  },
  mounted() {
    this.$updateDatabase(['invoiceGroups', 'customers', 'suppliers', 'projects', 'projectItems'])

    if (this.$route.name == 'purchaseDiscountsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    }
    if (this.$route.name == 'purchaseDiscountsCreate') {
      this.item.date = this.$nowDate()
    }
    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  methods: {
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.$parent.stopEdit = false
        this.item.date = this.$dateFormat(this.item.date, true)
      })
    },
    saveItem() {
      var error = 0
      if (
        this.item.client_type == 0 &&
        (typeof this.item.customer_id === 'undefined' || this.item.customer_id == '')
      ) {
        error = 1
        this.errors.name = true
      }
      if (
        this.item.client_type == 1 &&
        (typeof this.item.supplier_id === 'undefined' || this.item.supplier_id == '')
      ) {
        error = 1
        this.errors.name = true
      }
      if (
        this.item.client_type == 2 &&
        (typeof this.item.partner.name === 'undefined' || this.item.partner.name == '') &&
        this.$route.name == 'purchaseDiscountsCreate'
      ) {
        error = 1
        this.errors.name = true
      }
      if (typeof this.item.cost === 'undefined' || this.item.cost == '' || this.item.cost < 1) {
        error = 1
        this.errors.cost = true
      }
      if (this.$route.name == 'purchaseDiscountsCreate') {
        if (
          (typeof this.item.invoice_group === 'undefined' ||
            this.item.invoice_group == 0 ||
            this.item.invoice_group == '' ||
            this.item.invoice_group == null) &&
          this.$route.name == 'purchaseDiscountsCreate'
        ) {
          error = 1
          this.errors.invoice_group = true
        }
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == 'purchaseDiscountsCreate') {
          this.$toast.success(this.$t('Discount is added successfully'))
          const invoiceGroup = this.$database.invoiceGroups.find(
            (invoiceGroup) => invoiceGroup.id === this.item.invoice_group
          )

          this.item.code = invoiceGroup.prefix
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          if (this.item.client_type == 0) {
            this.item.old_balance = this.item.customer.balance
          } else if (this.item.client_type == 1) {
            this.item.old_balance = this.item.supplier.balance
          }
          this.item.new_balance = parseFloat(this.item.old_balance) - parseFloat(this.item.cost)

          // change customer balance
          if (this.item.client_type == 0) {
            var customer = this.$database.customers.find((el) => el.id == this.item.customer_id)
            if (customer) {
              customer.balance = this.item.new_balance
            }
          } else {
            var supplier = this.$database.suppliers.find((el) => el.id == this.item.supplier_id)
            if (supplier) {
              supplier.balance = this.item.new_balance
            }
          }

          localStorage.database = JSON.stringify(this.$database)

          this.$router.go(-1)
          this.$parent.stopEdit = false
        } else if (this.$route.name == 'purchaseDiscountsEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              if (response.data == 'safe error') {
                this.$toast.error(this.$t('Employee is not linked to a safe'))
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              } else if (response.data == 'money error') {
                this.$toast.error(this.$t('Amount is not available in the safe'))
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              } else {
                this.$toast.success(this.$t('Purchase payment has been modified successfully'))
                this.$router.go(-1)
                this.$parent.stopEdit = false
                return response
              }
            })
        }
      } else {
        this.$toast.error(this.$t('Please make sure of the input'))
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    loading,
    subFileInput,

    searchGroupInput,

    VueCtkDateTimePicker
  }
}
</script>
