<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Create a point of sale')"
          :description="$t('from here you can create a new point of sale')"
          v-if="$route.name == 'pointOfSalesCreate'"
        />
        <addHeader
          :title="$t('Point of sale adjustment')"
          :description="$t('From here you can Modify the point of sale data')"
          v-if="$route.name == 'pointOfSalesEdit'"
        />
        <div class="mb-4 row">
          <formInput
            :title="$t('Name point of sale')"
            :checkError="'nameErorr'"
            v-model="item.name"
            :hasErorr="errors.name"
            maxlength="255"
            :error="$t('This field is required')"
          />
          <searchGroupInput :type="'safe'" :values="$database.safes" :inputs="[{ show: 'name' }]" />
          <searchGroupInput
            :type="'store'"
            :values="$database.stores"
            :inputs="[{ show: 'name' }]"
          />
          <searchGroupInput
            :type="'customer'"
            :values="$database.customers"
            :inputs="[{ title: $t('VirtualAgent'), show: 'name' }]"
          />

          <connectionInput
            :title="$t('invoiceGroup')"
            :group="$t('invoiceGroups')"
            v-model="item.invoice_group_id"
            :hasAdd="false"
            :open="'addStore'"
            :values="$database.invoiceGroups"
            :hasErorr="errors.invoice_group_id"
            :error="$t('This field is required')"
          />

          <checkInput :title="$t('status')" :value="item.stat" v-model="item.stat" />

          <dvider
            :title="$t('Point of sale settings')"
            :description="$t('Point Of Sale Settings')"
          />
          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Printing method') }}</label>
            <div class="col-md-7">
              <select type="text" class="form-control" v-model="item.print_type">
                <option value="0">
                  {{ $t('No Printing') }}
                </option>
                <option value="1">
                  {{ $t('Thermal Paper(8cm)') }}
                </option>
                <option value="3">
                  {{ $t('Thermal Paper(5cm)') }}
                </option>
                <option value="2">{{ $t('A4 Paper') }}</option>
              </select>
            </div>
          </div>
          <formInput type="number" :title="$t('print Count')" v-model="item.print_count" />
          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('Invoice Language') }}</label>
            <div class="col-md-7">
              <select type="text" class="form-control" v-model="item.print_language">
                <option value="0">{{ $t('Arabic') }}</option>
                <option value="1">{{ $t('English') }}</option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{ $t('QuantityView') }}</label>
            <div class="col-md-7">
              <select type="text" class="form-control" v-model="item.print_amount">
                <option value="0">{{ $t('Normal') }}</option>
                <option value="1">{{ $t('double') }}</option>
              </select>
            </div>
          </div>
          <connectionInput
            :title="$t('Discount')"
            :group="$t('Discounts')"
            v-model="item.discount_id"
            :hasAdd="false"
            :values="$database.discounts"
          />
          <formInput type="number" :title="$t('maximum Discount')" v-model="item.max_discount" />
          <connectionInput
            :title="$t('Tax')"
            :group="$t('Taxes')"
            v-model="item.tax_id"
            :hasAdd="false"
            :values="$database.taxes"
          />
          <connectionInput
            :title="$t('List Of Prices')"
            :group="$t('Price Lists')"
            v-model="item.priceList_id"
            :hasAdd="false"
            :values="$database.priceLists"
          />
          <checkInput
            :title="$t('Balance View')"
            :value="item.show_balance"
            v-model="item.show_balance"
          />
          <checkInput
            :title="$t('Adjusting The Sale Price')"
            :value="item.change_price"
            v-model="item.change_price"
          />
          <checkInput
            :title="$t('Use Barcode')"
            :value="item.barcode_allow"
            v-model="item.barcode_allow"
          />

          <checkInput
            :title="$t('Discount Adjustment')"
            :value="item.change_discount"
            v-model="item.change_discount"
          />

          <checkInput
            :title="$t('Allow Discounting For Products')"
            :value="item.change_product_discount"
            v-model="item.change_product_discount"
          />

          <checkInput
            :title="$t('Adjust The PriceList')"
            :value="item.change_priceList"
            v-model="item.change_priceList"
          />

          <checkInput
            :title="$t('Add Notes')"
            :value="item.change_notes"
            v-model="item.change_notes"
          />

          <checkInput
            :title="$t('View Invoices')"
            :value="item.invoice_allow"
            v-model="item.invoice_allow"
          />
          <checkInput
            :title="$t('Using Marketers')"
            :value="item.marketer_allow"
            v-model="item.marketer_allow"
          />

          <checkInput
            :title="$t('Open The Cash Drawer')"
            :value="item.open_cash_drawer_allow"
            v-model="item.open_cash_drawer_allow"
          />

          <checkInput
            :title="$t('Multiple Invoices')"
            :value="item.multi_invoice"
            v-model="item.multi_invoice"
          />

          <checkInput :title="$t('show Rest')" :value="item.show_rest" v-model="item.show_rest" />

          <checkInput
            :title="$t('print User')"
            :value="item.print_user"
            v-model="item.print_user"
          />

          <dvider
            :title="$t('Customer Settings')"
            :description="$t('from here you can control customer returns')"
          />
          <checkInput
            :title="$t('Edit customer')"
            :value="item.change_price"
            v-model="item.change_customer"
          />
          <checkInput
            v-if="item.change_customer"
            :title="$t('Address editing')"
            :value="item.change_address"
            v-model="item.change_address"
          />
          <checkInput
            v-if="item.change_customer"
            :title="$t('Allowing Debts')"
            :value="item.rest_allow"
            v-model="item.rest_allow"
          />
          <checkInput
            v-if="item.change_customer"
            :title="$t('Add Payments')"
            :value="item.payment_allow"
            v-model="item.payment_allow"
          />

          <dvider
            :title="$t('Expenses settings')"
            :description="$t('From here you can control the expenses settings')"
          />

          <checkInput
            :title="$t('Add expenses')"
            :value="item.expense_allow"
            v-model="item.expense_allow"
          />

          <connectionInput
            :title="$t('expenses Invoice Group')"
            :group="$t('invoiceGroups')"
            v-if="item.expense_allow"
            v-model="item.expenses_invoice_group_id"
            :hasAdd="false"
            :values="$database.invoiceGroups"
            :hasErorr="errors.expenses_invoice_group_id"
            :error="$t('This field is required')"
          />

          <div class="form-group col-md-6 row" v-if="item.expense_allow">
            <label class="col-md-4">{{ $t('Available expenses sections') }}</label>
            <div class="col-md-7">
              <multiselect
                v-model="selectedExpensesSections"
                :options="$database.expenseSections"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('Choose the sections')"
                label="name"
                track-by="name"
                :hide-selected="true"
              />
            </div>
          </div>

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'pointOfSalesCreate' && !$parent.stopEdit"
          >
            {{ $t('Create A point of sale') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'pointOfSalesEdit' && !$parent.stopEdit"
          >
            {{ $t('Point of sale adjustment') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import checkInput from '@/elements/add/form/checkInput.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import addHeader from '@/elements/add/header.vue'
import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'

import dvider from '@/elements/add/dvider.vue'
import loading from '@/elements/add/loading.vue'

export default {
  data() {
    return {
      path: '/pointOfSales',
      selectedSections: [],
      selectedDeliveries: [],
      selectedExpensesSections: [],
      filterdSections: [],
      item: {
        stat: 1,
        print_type: 0,
        print_language: 0,
        print_amount: 0,
        print_count: 1,
        show_balance: 0,
        show_earn: 0,
        show_cost: 0,
        change_price: 0,
        show_products: 0,
        show_products_image: 0,
        show_sections: 0,
        show_sections_image: 0,
        change_customer: 0,
        change_address: 0,
        rest_allow: 0,
        barcode_allow: 0,
        hall_allow: 0,
        delivery_allow: 0,
        delivery_id: '',
        marketer_allow: 0,
        pay_methods_allow: 0,
        open_cash_drawer_allow: 0,
        change_discount: 0,
        change_product_discount: 0,
        change_priceList: 0,
        change_notes: 0,
        kitchen_print: 0,
        multi_print: 0,
        multi_print_customer: 0,
        comment_allow: 0,
        invoice_allow: 0,
        order_number: 0,
        local_server: 0,
        invoice_show_products: 1,
        discount_id: 0,
        tax_id: 0,
        priceList_id: 0,
        multi_invoice: 0,
        hall_tables: [],
        printers: [],
        payment_methods: [],
        split_allow: 0,
        delete_after_print: 0,
        shipping_operations_invoice_group_id: 0,
        pickup_location_id: 0,
        expense_allow: 0,
        shipping_allow: 0,
        city_id: 0,
        show_rest: 0,
        print_user: 0,
        safe: {},
        store: {},
        customer: {}
      },
      errors: {
        name: false,
        safe_id: false,
        store_id: false,
        customer_id: false,
        invoice_group_id: false
      }
    }
  },
  mounted() {
    this.$updateDatabase([
      'stores',
      'sections',
      'discounts',
      'priceLists',
      'safes',
      'customers',
      'invoiceGroups',
      'deliveries',
      'pickupLocations'
    ])
    if (this.$route.name == 'pointOfSalesEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    } else {
      this.addQuotationItem()
    }
    this.changeSections()
    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  methods: {
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.item.section_id = JSON.parse(this.item.section_id)
        if (Array.isArray(this.item.section_id)) {
          this.item.section_id.forEach((id) => {
            var section = this.$database.sections.find((el) => el.id == id)
            if (section) {
              this.selectedSections.push(section)
            }
            if (id == 0) {
              this.selectedSections.push({
                id: 0,
                name: this.$t('Uncategorized')
              })
            }
          })
        }

        this.item.delivery_id = JSON.parse(this.item.delivery_id)
        if (Array.isArray(this.item.delivery_id)) {
          this.item.delivery_id.forEach((id) => {
            var deliveries = this.$database.deliveries.find((el) => el.id == id)
            if (deliveries) {
              this.selectedDeliveries.push(deliveries)
            }
          })
        }

        this.item.expenses_section_id = JSON.parse(this.item.expenses_section_id)
        if (Array.isArray(this.item.expenses_section_id)) {
          this.item.expenses_section_id.forEach((id) => {
            var expensesSections = this.$database.expenseSections.find((el) => el.id == id)
            if (expensesSections) {
              this.selectedExpensesSections.push(expensesSections)
            }
          })
        }

        if (this.item.payment_methods.length == 0) {
          this.addQuotationItem()
        }
        this.$parent.stopEdit = false
      })
    },
    saveItem() {
      var error = 0
      if (typeof this.item.name === 'undefined' || this.item.name == '') {
        error = 1
        this.errors.name = true
      }
      if (typeof this.item.safe_id === 'undefined' || this.item.safe_id == '') {
        error = 1
        this.errors.safe_id = true
      }
      if (typeof this.item.store_id === 'undefined' || this.item.store_id == '') {
        error = 1
        this.errors.store_id = true
      }
      if (typeof this.item.customer_id === 'undefined' || this.item.customer_id == '') {
        error = 1
        this.errors.customer_id = true
      }
      if (typeof this.item.invoice_group_id === 'undefined' || this.item.invoice_group_id == '') {
        error = 1
        this.errors.invoice_group_id = true
      }
      if (error == 0) {
        if (this.$route.name == 'pointOfSalesCreate') {
          this.$toast.success(this.$t('Point of sale successfully added'))
          this.$addToLocalDatabase(this.item, this.path.substring(1))
          this.$router.go(-1)
        } else if (this.$route.name == 'pointOfSalesEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$toast.success(this.$t('Point Of Sale has Been Modified Successfully'))
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$toast.error(this.$t('Please make sure of the input'))
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },

    addTable() {
      this.item.hall_tables.push({
        name: '',
        priceList_id: 0
      })
    },
    addPrinter() {
      this.item.printers.push({
        name: ''
      })
    },
    changeSections() {
      var sections = JSON.parse(JSON.stringify(this.$database.sections))
      sections.push({
        id: 0,
        name: this.$t('Uncategorized')
      })

      this.filterdSections = sections
    },
    addQuotationItem() {
      this.item.payment_methods.push({
        safe_id: '',
        name: ''
      })
    }
  },

  watch: {
    '$database.sections': function () {
      this.changeSections()
    },
    selectedSections: function (val) {
      this.item.section_id = JSON.stringify(val.map((el) => el.id))
    },
    selectedDeliveries: function (val) {
      this.item.delivery_id = JSON.stringify(val.map((el) => el.id))
    },
    selectedExpensesSections: function (val) {
      this.item.expenses_section_id = JSON.stringify(val.map((el) => el.id))
    }
  },
  components: {
    formInput,
    formTextarea,
    addHeader,
    connectionInput,
    checkInput,
    loading,
    dvider,
    searchGroupInput
  }
}
</script>
