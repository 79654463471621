var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('reports'),"description":_vm.$t('the Most Active Customers')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Report options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('customer'),"plaseholder":_vm.$t('choose Customer'),"show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.$database.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('section Customer'),"plaseholder":_vm.$t('choose Section'),"show":'name',"refrance":'id',"value":_vm.params.sections_account_id,"values":_vm.$database.sectionsAccounts},model:{value:(_vm.params.sections_account_id),callback:function ($$v) {_vm.$set(_vm.params, "sections_account_id", $$v)},expression:"params.sections_account_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('the Most Active Customers'),"details":[
            {
              title: _vm.$t('customer Name'),
              value: _vm.customerName
            },
            {
              title: _vm.$t('section Customer'),
              value: _vm.sectionCustomerName
            }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there No Data The Moment'),"emptyTableSubText":_vm.$t('try Adding Some And Try Again'),"cloumns":[
            {
              column: 'name',
              link: '/customers',
              title: _vm.$t('Customer'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'mobile',
              title: _vm.$t('Phone number'),
              type: 'text',
              sort: 'true'
            },
            {
              column: 'total',
              title: _vm.$t('treatment'),
              type: 'text',
              sort: 'true'
            }
          ],"footer":[
            { colspan: 2, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.total }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }