<template>
  <div class="row container-fluid">
    <div class="col-12">
      <PageHeader
        :title="$t('Discounts')"
        :description="$t('From here you can control your Discounts reports')"
      />
    </div>
    <!-- تقارير الخصومات -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('Discounts')"
      :icon="'fa-badge-percent'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 29 || el.id == 30) || $user.admin) &&
        ($site.salesDiscounts_allow || $site.purchaseDiscounts_allow) &&
        ($user.admin || $user.role.sales_discounts_show || $user.role.purchase_discounts_show)
      "
      :reports="[
        {
          name: $t('Record Sales Discounts'),
          link: './discountsHistory',
          role: $user.role.available_reports.find((el) => el.id == 29) || $user.admin
        },
        {
          name: $t('Record Purchase Discounts'),
          link: '/purchaseDiscountsHistory',
          role: $user.role.available_reports.find((el) => el.id == 30) || $user.admin
        }
      ]"
    />
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import PageHeader from '@/components/pageHeader'
export default {
  components: {
    reportsCard,
    PageHeader
  }
}
</script>
