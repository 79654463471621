<template>
  <Layout>
    <!-- page header component -->
    <PageHeader
      :title="$t('Point of sales expenses')"
      :description="$t('From here you can control POS expenses')"
    />
    <!-- index table component-->
    <indexTable
      :referenceId="referenceId"
      :addActionRole="$checkRole('expenses_add')"
      @addAction="openAdd"
      :loading="loading"
      :error="error"
      :deleteId="deleteId"
      :page="params.page"
      :totalPages="totalPages"
      :perPage="params.perPage"
      :items="items"
      :searchText="$t('Find a point of sale expense')"
      :columnHeaders="[
        {
          sort: 'code',
          title: $t('Code')
        },
        {
          sort: 'date',
          title: $t('Date')
        },
        {
          sort: 'expense_section_id',
          title: $t('section')
        },
        {
          sort: 'description',
          title: $t('thatsAbout')
        },
        {
          sort: 'cost',
          title: $t('amount')
        },

        {
          title: $t('Actions')
        }
      ]"
      @sortItems="sortItems"
      @closeDelete="closeDelete"
      @deleteItem="deleteItem"
      @changePage="changePage"
      @changeSearch="changeSearch"
      @changePerPage="changePerPage"
    >
      <tbody class="list">
        <tr
          v-for="(item, index) in items"
          :key="index"
          @click="toggleSetting(index)"
          :class="{ active: item.ShowSitting }"
        >
          <td>
            <router-link :to="'/expenses/' + item.id"> # {{ item?.code }} </router-link>
          </td>
          <td>
            {{ item?.date ?? '--' }}
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Section') }}</strong>
            {{ item?.expenseSection?.name ?? '--' }}
          </td>

          <td>
            <strong class="mobile-title">{{ $t('Description') }}</strong>
            {{ item?.description ?? '--' }}
          </td>
          <td>
            <strong class="mobile-title">{{ $t('cost') }}</strong>
            {{ item?.cost ?? '--' }}
          </td>

          <td class="text-right edit-td">
            <div class="dropdown" :class="{ show: item.showOptions }">
              <i
                class="far fa-ellipsis-h"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                :aria-expanded="item.showOptions"
                @click="toggleOptions(index, $event)"
              ></i>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                :class="{ show: item.showOptions }"
                :style="'top: ' + item.top + 'px; left: ' + item.left + 'px'"
              >
                <router-link :to="'/expenses/' + item.id" class="dropdown-item">
                  <i class="far fa-search"></i>
                  {{ $t('Show') }}
                </router-link>
                <router-link
                  :to="'/expenses/' + item.id + '/edit'"
                  class="dropdown-item"
                  v-if="$checkRole('expenses_edit')"
                >
                  <i class="far fa-edit"></i>
                  {{ $t('Edit') }}
                </router-link>
                <a
                  @click="sureDelete(index)"
                  class="dropdown-item"
                  v-if="$checkRole('expenses_delete')"
                >
                  <i class="far fa-trash"></i>
                  {{ $t('Delete') }}
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </indexTable>
    <ExpenseForm v-if="addForm" @close="closeAdd" />
  </Layout>
</template>

<script setup>
import Vue from 'vue'
import { ref } from 'vue'
// layout component
import Layout from '@/layouts/main.vue'
// page components
import PageHeader from '@/components/pageHeader'
import indexTable from '@/components/table/index'

// helpers functions
import tableHock from '@/helpers/tableHock'
import ExpenseForm from './components/popUp/expenseForm.vue'

// Get pos id
const url = window.location.href
const segments = url.split('/')
const id = segments[segments.length - 2]
const POS = Vue.prototype.$database.pointOfSales.find((el) => el.id == id)
const sessionNumber = POS.session_number ?? ''

const {
  referenceId,
  items,
  totalPages,
  loading,
  error,
  deleteId,
  params,
  sortItems,
  sureDelete,
  toggleOptions,
  toggleSetting,
  closeDelete,
  changePerPage,
  deleteItem,
  changePage,
  changeSearch
} = tableHock({
  path: 'expenses',
  cache: false,
  filter: { point_of_sale_id: id, session_number: sessionNumber }
})

// Print logic
const addForm = ref(false)
const openAdd = () => (addForm.value = true)
const closeAdd = () => (addForm.value = null)
</script>
