var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('employee account total')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Report Options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Employee'),"plaseholder":_vm.$t('Choose Employee'),"show":'name',"refrance":'id',"value":_vm.params.employee_id,"values":_vm.$database.employees},model:{value:(_vm.params.employee_id),callback:function ($$v) {_vm.$set(_vm.params, "employee_id", $$v)},expression:"params.employee_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('employee account total'),"details":[{ title: _vm.$t('Employee Name'), value: _vm.employeeName }]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 6, value: _vm.$t('Opening Balance') },
            {
              colspan: 1,
              value: _vm.startBalance
            }
          ],"emptyTableText":_vm.$t('there are no data'),"emptyTableSubText":_vm.$t('Try adding Some and try again'),"cloumns":[
            { column: 'code', title: _vm.$t('Code'), type: 'reportCode' },
            { column: 'date', title: _vm.$t('Date'), type: 'text' },
            { column: 'type', title: _vm.$t('type'), type: 'reportType' },
            { column: 'statement', title: _vm.$t('Statement'), type: 'statement' },
            {
              column: 'debit',
              title: _vm.$t('Debit'),
              type: 'textOrEmpty'
            },
            {
              column: 'creditor',
              title: _vm.$t('Credit'),
              type: 'textOrEmpty'
            },
            {
              column: 'new_balance',
              title: _vm.$t('Balance'),
              type: 'text'
            }
          ],"footer":[
            { colspan: 4, value: _vm.$t('Final Balance') },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            {
              colspan: 1,
              value: _vm.endBalance
            }
          ],"tafqeet":_vm.tafqeet}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }