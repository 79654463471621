var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'user',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('partners_edit')
        },
        {
          title: _vm.$t('Comprehensive report'),
          icon: 'file-chart-pie',
          link: '/partnersDetailsAccounts?partner_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('reports_show')
        }
      ]}}),_c('div',{staticClass:"col-12"},[_c('showFile')],1),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Background'),"cloumns":{
        firstCloum: [
          {
            icon: 'user',
            title: _vm.$t('Partner name'),
            value: _vm.item.name
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('Balance'),
            value: _vm.$moneyFormat(_vm.$option.balance_type == 1 ? _vm.item.balance * -1 : _vm.item.balance)
          }
        ],
        scondCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Opening balance'),
            value: _vm.$moneyFormat(_vm.item.opening_balance)
          },
          {
            icon: 'toggle-on',
            title: _vm.$t('Partner status'),
            value: _vm.item.stat == 1 ? _vm.$t('Effective') : _vm.$t('Ineffective')
          }
        ]
      }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Billing address'),"cloumns":{
        firstCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('Address'),
            value: _vm.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('The side'),
            value: _vm.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('Zip code'),
            value: _vm.item.zip
          }
        ],
        scondCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('The sequel'),
            value: _vm.item.address2
          },
          { icon: 'city', title: _vm.$t('City'), value: _vm.item.city },
          {
            icon: 'globe-americas',
            title: _vm.$t('State'),
            value: _vm.item.country
          }
        ]
      }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Communication  information'),"cloumns":{
        firstCloum: [
          {
            icon: 'phone',
            title: _vm.$t('Mobile number'),
            value: _vm.item.mobile
          }
        ],
        scondCloum: [{ icon: 'at', title: _vm.$t('Email'), value: _vm.item.email }]
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }