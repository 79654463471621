<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <PageHeader :title="$t('Reports')" :description="$t('Inactive Customers')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t('Reports options') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('Customer')"
                :plaseholder="$t('Choose Customer')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.customer_id"
                :value="params.customer_id"
                :values="$database.customers"
              />
              <searchInput
                class="col-md-3"
                :title="$t('section Customer')"
                :plaseholder="$t('choose Section')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.sections_account_id"
                :value="params.sections_account_id"
                :values="$database.sectionsAccounts"
              />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>

        <div id="reportShow">
          <reportHeader
            :title="$t('Inactive Customers')"
            :details="[
              { title: $t('Customer name'), value: customerName },
              {
                title: $t('section Customer'),
                value: sectionCustomerName
              }
            ]"
          />
          <reportTable
            :emptyTableText="$t('There are no data')"
            :emptyTableSubText="$t('Try adding some and try again')"
            :cloumns="[
              {
                column: 'name',
                link: '/customers',
                title: $t('Customer Name'),
                type: 'reportMainLink',
                sort: true
              },
              {
                column: 'balance',
                title: $t('Balance'),
                type: 'text',
                sort: true
              },
              {
                column: 'date',
                title: $t('date of the last transaction'),
                type: 'text',
                sort: true
              },
              {
                column: 'cost',
                title: $t('amount of the last transaction'),
                type: 'text',
                sort: true
              }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import reportHeader from '@/elements/reports/reportHeader.vue'
import reportTable from '@/elements/reports/reportTable.vue'
import PageHeader from '@/components/pageHeader'
import searchInput from '@/elements/reports/searchInput.vue'
import actionButtons from '@/elements/reports/actionButtons.vue'
export default {
  data() {
    return {
      path: '/reports/customers/customersInactive',
      items: [],

      loading: 0,

      balance: 0,

      customers: [],
      customerName: '',
      sectionCustomerName: '',

      params: {
        customer_id: '',
        sections_account_id: ''
      },
      excel: {
        fileName: 'customers inactive',
        reportData: '[]'
      }
    }
  },
  methods: {
    getData() {
      this.$setParams(this.params)
      this.loading = 1
      this.items = []
      axios.get(this.$linkGenerator(this.path), { params: this.params }).then((response) => {
        this.items = response.data

        var customer = this.$database.customers.find((el) => el.id == this.params.customer_id)
        if (customer) {
          this.customerName = customer.name
        } else {
          this.customerName = ''
        }

        var sectionCustomer = this.$database.sectionsAccounts.find(
          (el) => el.id == this.params.sections_account_id
        )
        if (sectionCustomer) {
          this.sectionCustomerName = sectionCustomer.name
        } else {
          this.sectionCustomerName = ''
        }

        this.loading = 0
      })
    }
  },
  watch: {
    items: function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map((r) =>
          [...r.querySelectorAll('th, td')].map((td) => td.textContent)
        )
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData)
      }, 100)
      this.items.forEach((item) => {
        item.balance = this.$moneyFormat(item.balance)
        item.cost = this.$moneyFormat(item.cost)
        item.date = item.date == 0 ? '--' : this.$dateFormat(item.date)
      })
    }
  },
  mounted() {
    this.params = this.$getParams()
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date()
        .toISOString('en-US', { timeZone: 'Africa/Cairo' })
        .slice(0, 10)
    }
    this.getData()
  },
  components: {
    reportHeader,
    reportTable,
    PageHeader,
    searchInput,
    actionButtons
  }
}
</script>
