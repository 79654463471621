var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('shippingOperations'),"description":_vm.$t('From here you can control your shipping Operations reports')}})],1),(
      (_vm.$user.role.available_reports.find((el) => el.id == 13 || el.id == 14) || _vm.$user.admin) &&
      _vm.$site.cities_allow
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('shipping Operations'),"icon":'far fa-shipping-fast',"reports":[
      {
        name: _vm.$t('shipping Operations'),
        link: './shippingOperationsByInvoice',
        role: _vm.$user.role.available_reports.find((el) => el.id == 13) || _vm.$user.admin
      }
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }