var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('partners'),"description":_vm.$t('From here you can control your partners reports')}})],1),(
      (_vm.$user.role.available_reports.find(
        (el) => el.id == 34 || el.id == 35 || el.id == 36 || el.id == 37
      ) ||
        _vm.$user.admin) &&
      _vm.$site.partners_allow
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('partners'),"icon":'fa-handshake',"reports":[
      {
        name: _vm.$t('Total Partner Account'),
        link: './partnersTotalAccounts',
        role: _vm.$user.role.available_reports.find((el) => el.id == 34) || _vm.$user.admin
      },
      {
        name: _vm.$t('Partner Account Details'),
        link: '/partnersDetailsAccounts',
        role: _vm.$user.role.available_reports.find((el) => el.id == 35) || _vm.$user.admin
      },
      {
        name: _vm.$t('Partners Assets'),
        link: '/partnersBalance',
        role: _vm.$user.role.available_reports.find((el) => el.id == 36) || _vm.$user.admin
      },
      {
        name: _vm.$t('Partner Guide'),
        link: '/partnersGuide',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      },
      {
        name: _vm.$t('partner Movement'),
        link: '/partnerMovement',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      }
    ]}}):_vm._e(),(
      (_vm.$user.role.available_reports.find((el) => el.id == 31 || el.id == 32) || _vm.$user.admin) &&
      (_vm.$site.partnersDeposits_allow ||
        _vm.$site.partnersWithdrawals_allow ||
        _vm.$site.partnersSettlements_allow ||
        _vm.$site.partnersProfits_allow)
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('Partners Operations'),"icon":'fa-handshake',"reports":[
      {
        name: _vm.$t('Partner Deposits History'),
        link: './partnerDepositsHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 31) || _vm.$user.admin
      },
      {
        name: _vm.$t('Partner Withdrawals History'),
        link: '/partnerWithdrawalsHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 32) || _vm.$user.admin
      }
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }