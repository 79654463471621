var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('Withdrawal by product')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":" تصنيف المنتجات ","plaseholder":"اختر تصنيف","show":'name',"refrance":'id',"value":_vm.params.section_id,"values":_vm.$database.sections},model:{value:(_vm.params.section_id),callback:function ($$v) {_vm.$set(_vm.params, "section_id", $$v)},expression:"params.section_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Store'),"plaseholder":_vm.$t('Choose store'),"show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('Withdrawal by product'),"details":[
            { title: _vm.$t('Username'), value: _vm.userName },
            { title: _vm.$t('Store'), value: _vm.storeName },
            { title: 'المنتج', value: _vm.productName },
            { title: 'التصنيف', value: _vm.sectionName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'store_id',
              title: _vm.$t('Store name'),
              type: 'link',
              to: 'store',
              sort: true,
              link: true
            },
            {
              column: 'product_name',
              link: '/products',
              title: _vm.$t('Product name'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'quantity',
              title: _vm.$t('Withdrawn quantities'),
              type: 'text',
              sort: true
            }
          ],"footer":[
            { colspan: 2, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.quantity }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }