var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Layout,[(_vm.$route.name == 'sectionsCreate')?_c(_setup.PageHeader,{attrs:{"title":_vm.$t('Create Section'),"description":_vm.$t('From here you can create a new product section')}}):_vm._e(),(_vm.$route.name == 'sectionsEdit')?_c(_setup.PageHeader,{attrs:{"title":_vm.$t('Edit section'),"description":_vm.$t('From here you can modify the section data')}}):_vm._e(),_c(_setup.Form,{attrs:{"loading":_setup.loading,"params":_setup.params},on:{"submit":_setup.onSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c(_setup.Divider,{attrs:{"title":_vm.$t('Section Information'),"description":_vm.$t('Basic Section Information'),"noLine":true}})],1),_c('div',{staticClass:"col-md-6"},[_c(_setup.Input,{attrs:{"type":"text","title":_vm.$t('Section Name'),"errors":_setup.getInputError('name')},model:{value:(_setup.item.name),callback:function ($$v) {_vm.$set(_setup.item, "name", $$v)},expression:"item.name"}})],1),_c('div',{staticClass:"col-md-6"},[_c(_setup.SearchInput,{attrs:{"title":_vm.$t('father Section'),"options":_vm.$database.sections,"errors":_setup.getInputError('parent_id')},model:{value:(_setup.item.parent_id),callback:function ($$v) {_vm.$set(_setup.item, "parent_id", $$v)},expression:"item.parent_id"}})],1),_c('div',{staticClass:"col-md-6"},[_c(_setup.Select,{attrs:{"title":_vm.$t('classification Icon'),"values":[
            { value: 'cake', name: 'Cake' },
            { value: 'capuccino', name: 'Cappuccino' },
            { value: 'cauliflower', name: 'Cauliflower' },
            { value: 'cola', name: 'Cola' },
            { value: 'crepe', name: 'Crepe' },
            { value: 'cup-of-drink', name: 'Cup of Drink' },
            { value: 'dessert', name: 'Dessert' },
            { value: 'donut', name: 'Donut' },
            { value: 'drink', name: 'Drink' },
            { value: 'ezgif.com-gif-maker', name: 'Ezgif' },
            { value: 'fast-food', name: 'Fast Food' },
            { value: 'fish', name: 'Fish' },
            { value: 'food', name: 'Food' },
            { value: 'french-fries', name: 'French Fries' },
            { value: 'meat', name: 'Meat' },
            { value: 'milk', name: 'Milk' },
            { value: 'pasta', name: 'Pasta' },
            { value: 'pizza', name: 'Pizza' },
            { value: 'roast-chicken', name: 'Roast Chicken' },
            { value: 'salad', name: 'Salad' },
            { value: 'salt', name: 'Salt' },
            { value: 'sandwich', name: 'Sandwich' },
            { value: 'shrimp', name: 'Shrimp' },
            { value: 'skewer', name: 'Skewer' },
            { value: 'sugar', name: 'Sugar' },
            { value: 'tea', name: 'Tea' },
            { value: 'vegetables', name: 'Vegetables' },
            { value: 'waffle', name: 'Waffle' }
          ],"errors":_setup.getInputError('icon')},model:{value:(_setup.item.icon),callback:function ($$v) {_vm.$set(_setup.item, "icon", $$v)},expression:"item.icon"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }