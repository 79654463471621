<template>
  <div class="mb-4">
    <slot />
    <hr class="mt-5 mb-5 col-12" />
    <button class="btn btn-block btn-primary disabled" v-if="loading">
      {{ $t('Loading') }}
      <span class="loading-s1 sm-j">.</span>
      <span class="loading-s2 sm-j">.</span>
      <span class="loading-s3 sm-j">.</span>
    </button>
    <button type="button" class="btn btn-block btn-primary" v-else @click="$emit('submit')">
      {{ $route.name == params.add.routeName ? $t('Create') : $t('Update') }}
    </button>
  </div>
</template>
<script>
export default {
  props: ['loading', 'params']
}
</script>
