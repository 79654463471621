<template>
  <th>
    <span class="text-muted" :class="sort ? 'list-sort' : ''" @click="sort ? sortItems(sort) : ''">
      {{ text }}
    </span>
  </th>
</template>
<script>
export default {
  props: ['text', 'sort'],
  methods: {
    sortItems(name) {
      this.$emit('sortItems', name)
    }
  }
}
</script>
