<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :icon="'store-alt'"
        :title="item.name"
        :subData="[
          { title: $t('The state'), value: item.country },
          { title: $t('City'), value: item.city }
        ]"
        :buttons="[
          {
            title: $t('Modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('stores_edit')
          },
          {
            title: $t('Inventory of the store'),
            icon: 'file-chart-pie',
            link: '/warehouseInventory?store_id=' + $route.params.id,
            role: $checkRole('reports_show')
          }
        ]"
      />
      <showCard
        class="col-12"
        :title="$t('Store address')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('Address'),
              value: item.address1
            },
            {
              icon: 'location-arrow',
              title: $t('The side'),
              value: item.entity
            },
            {
              icon: 'map-marked',
              title: $t('Zip code'),
              value: item.zip
            }
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('The sequel'),
              value: item.address2
            },
            { icon: 'city', title: $t('City'), value: item.city },
            {
              icon: 'globe-americas',
              title: $t('State'),
              value: item.country
            }
          ]
        }"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'

export default {
  data() {
    return {
      path: '/stores',
      item: {}
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      var item = this.$database.stores.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    }
  },
  components: {
    showHeader,
    showCard
  }
}
</script>
<style></style>
