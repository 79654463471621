<template>
  <div>
    <div class="card-header">
      <div class="input-group input-group-flush">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="fa fa-search"></i>
          </span>
        </div>
        <input
          class="form-control list-search"
          type="search"
          :placeholder="searchText ?? $t('Search For Item')"
          v-model="search"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['searchText', 'searchObject'],
  data() {
    return {
      search: '',
      awaitingSearch: false
    }
  },
  watch: {
    search: function (value) {
      if (!this.awaitingSetParam) {
        clearTimeout(this.awaitingSearch)
        this.awaitingSearch = setTimeout(() => {
          this.$emit('changeSearch', value)
        }, 400)
      }
    }
  }
}
</script>
