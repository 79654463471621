var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'treasure-chest',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('Active') : _vm.$t('Inactive'),"subData":[
        {
          title: _vm.$t('Current balance'),
          value: this.$moneyFormat(_vm.item.balance)
        }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('safes_edit')
        }
      ]}}),_c('showBoxs',{staticClass:"col-12",attrs:{"boxs":[
        {
          icon: 'dollar-sign',
          title: _vm.$t('Current balance'),
          value: this.$moneyFormat(_vm.item.balance),
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('Invoices count'),
          value: _vm.item.salesPaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('Quotations count'),
          value: _vm.item.purchasePaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'usd-square',
          title: _vm.$t('Orders count'),
          value: _vm.item.expensesCount,
          class: 'col-md-3'
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('Account info'),"cloumns":{
        firstCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Balance'),
            value: this.$moneyFormat(_vm.item.balance)
          }
        ],
        scondCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('Opening balance'),
            value: this.$moneyFormat(_vm.item.opening_balance)
          }
        ]
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }