<template>
  <Layout>
    <!-- page header component -->
    <PageHeader
      :title="$t('Point of sales invoices')"
      :description="$t('From here you can control POS invoices')"
    />
    <!-- index table component-->
    <indexTable
      :referenceId="referenceId"
      :addLink="$checkRole('point_of_sales_add') ? '/pointofsales/' + this.$route.params.id : ''"
      :loading="loading"
      :error="error"
      :deleteId="deleteId"
      :page="params.page"
      :totalPages="totalPages"
      :perPage="params.perPage"
      :items="items"
      :searchText="$t('Find a point of sale')"
      :columnHeaders="[
        {
          sort: 'code',
          title: $t('Invoice')
        },
        {
          sort: 'date',
          title: $t('Invoice date')
        },
        {
          title: $t('Customer name')
        },
        {
          sort: 'total',
          title: $t('Cost')
        },
        {
          sort: 'rest',
          title: $t('Rest')
        },
        {
          sort: 'user_name',
          title: $t('Responsible name')
        },
        {
          sort: 'status',
          title: $t('Situation')
        },
        {
          title: $t('Settings')
        }
      ]"
      @sortItems="sortItems"
      @closeDelete="closeDelete"
      @deleteItem="deleteItem"
      @changePage="changePage"
      @changeSearch="changeSearch"
      @changePerPage="changePerPage"
    >
      <tbody class="list">
        <tr
          v-for="(item, index) in items"
          :key="index"
          @click="toggleSetting(index)"
          :class="{ active: item.ShowSitting }"
        >
          <td>
            <router-link :to="'/invoices/' + item.id"> # {{ item.code }} </router-link>
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Invoice date') }}</strong>
            {{ item.date ?? '--' }}
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Customer name') }}</strong>
            <router-link :to="'/customers/' + item.customer?.id" v-if="item.client_type == 0">
              {{ item.customer?.name }}
            </router-link>
            <router-link :to="'/suppliers/' + item.supplier?.id" v-if="item.client_type == 1">
              {{ item.supplier?.name }}
            </router-link>
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Cost') }}</strong>
            {{ item.total }}
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Rest') }}</strong>
            {{ item.rest }}
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Responsible name') }}</strong>
            {{ item.user_name }}
          </td>
          <td>
            <div class="badge badge-dark" v-if="item.status == 0">{{ $t('Draft') }}</div>
            <div class="badge badge-primary" v-if="item.status == 1">{{ $t('Sent') }}</div>
            <div class="badge badge-primary" v-if="item.status == 2">{{ $t('Seen') }}</div>
            <div class="badge badge-success" v-if="item.status == 3">{{ $t('Driven') }}</div>
            <div class="badge badge-danger" v-if="item.status == 4">{{ $t('Canceled') }}</div>
          </td>

          <td class="text-right edit-td">
            <div class="dropdown" :class="{ show: item.showOptions }">
              <i
                class="far fa-ellipsis-h"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                :aria-expanded="item.showOptions"
                @click="toggleOptions(index, $event)"
              ></i>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                :class="{ show: item.showOptions }"
                :style="'top: ' + item.top + 'px; left: ' + item.left + 'px'"
              >
                <a class="dropdown-item" @click="openShowInvoice(item.id)">
                  <i class="far fa-search"></i>
                  {{ $t('Show') }}
                </a>
                <router-link 
                  :to="'/invoices/edit/' + item.id"
                  class="dropdown-item"
                   v-if="$checkRole('invoices_edit') && !$option.tax_invoice"
                  >
                  <i class="far fa-edit"></i>
                  {{ $t('edit') }}
                </router-link>
                <a class="dropdown-item" @click="print(item.id)">
                  <i class="far fa-print"></i>
                  {{ $t('Print') }}
                </a>
                <router-link
                  :to="'/saleReturns/create?invoice_id=' + item?.id"
                  class="dropdown-item"
                  v-if="$checkRole('sale_returns_add')"
                >
                  <i class="far fa-exchange"></i>
                  {{ $t('Return invoice') }}
                </router-link>

                <a
                  @click="sureDelete(index) && !$option.tax_invoice"
                  class="dropdown-item"
                  v-if="$checkRole('invoices_delete')"
                >
                  <i class="far fa-trash"></i>
                  {{ $t('Delete') }}
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </indexTable>
    <ShowInvoice v-if="showInvoiceId" :id="showInvoiceId" @close="closeShowInvoice" />
    <PrintPointOfSale v-if="printInvoice" :item="printInvoice" @closePrint="closePrint" />
  </Layout>
</template>

<script setup>
import axios from 'axios'
import Vue from 'vue'
import { ref } from 'vue'
// layout component
import Layout from '@/layouts/main.vue'
// page components
import PageHeader from '@/components/pageHeader'
import indexTable from '@/components/table/index'
import ShowInvoice from './components/popUp/showInvoice.vue'
import PrintPointOfSale from '@/print/pointOfSale'
// helpers functions
import tableHock from '@/helpers/tableHock'

// Get pos id
const url = window.location.href
const segments = url.split('/')
const id = segments[segments.length - 2]
const POS = Vue.prototype.$database.pointOfSales.find((el) => el.id == id)
const sessionNumber = POS.session_number ?? ''

const {
  referenceId,
  items,
  totalPages,
  loading,
  error,
  deleteId,
  params,
  sortItems,
  sureDelete,
  toggleOptions,
  toggleSetting,
  closeDelete,
  changePerPage,
  deleteItem,
  changePage,
  changeSearch
} = tableHock({
  path: 'invoices',
  cache: false,
  filter: { point_of_sale_id: id, session_number: sessionNumber }
})

const showInvoiceId = ref(null)
const openShowInvoice = (id) => (showInvoiceId.value = id)
const closeShowInvoice = () => (showInvoiceId.value = null)

// Print logic
const printInvoice = ref(null)
const print = (id) => {
  axios.get(Vue.prototype.$linkGenerator('/invoices/' + id)).then((response) => {
    printInvoice.value = response.data
  })
}
const closePrint = () => (printInvoice.value = null)
</script>
