var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'store-alt',"title":_vm.item.name,"subData":[
        { title: _vm.$t('The state'), value: _vm.item.country },
        { title: _vm.$t('City'), value: _vm.item.city }
      ],"buttons":[
        {
          title: _vm.$t('Modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('pickup_locations_edit')
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('the Address Of The Pickup Locations'),"cloumns":{
        firstCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('Title'),
            value: _vm.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('The entity'),
            value: _vm.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('Postal code'),
            value: _vm.item.zip
          }
        ],
        scondCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('Supplement'),
            value: _vm.item.address2
          },
          {
            icon: 'city',
            title: _vm.$t('City'),
            value: _vm.item.city
          },
          {
            icon: 'globe-americas',
            title: _vm.$t('The state'),
            value: _vm.item.country
          }
        ]
      }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('contact Information'),"cloumns":{
        firstCloum: [
          {
            icon: 'phone',
            title: _vm.$t('Mobile number'),
            value: _vm.item.mobile
          }
        ],
        scondCloum: [
          {
            icon: 'phone',
            title: _vm.$t('another Number'),
            value: _vm.item.mobile2
          }
        ]
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }