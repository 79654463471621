var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('remaining balance for employees')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Employee'),"plaseholder":_vm.$t('Choose Employee'),"show":'name',"refrance":'id',"value":_vm.params.employee_id,"values":_vm.$database.employees},model:{value:(_vm.params.employee_id),callback:function ($$v) {_vm.$set(_vm.params, "employee_id", $$v)},expression:"params.employee_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('remaining balance for employees'),"details":[{ title: _vm.$t('Employee name'), value: _vm.employeeName }]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data'),"emptyTableSubText":_vm.$t('Try adding some and try again'),"cloumns":[
            {
              column: 'name',
              link: '/employees',
              title: _vm.$t('Employee name'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'balance',
              title: _vm.$t('Balance'),
              type: 'text',
              sort: 'true'
            }
          ],"footer":[
            { colspan: 1, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.balance }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }