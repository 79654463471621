var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":_vm.$t('purchase discount record')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Supplier'),"plaseholder":_vm.$t('Choose supplier'),"show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.$database.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Customer'),"plaseholder":_vm.$t('Choose Customer'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.$database.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Project'),"plaseholder":_vm.$t('Choose Project'),"show":'name',"refrance":'id',"value":_vm.params.project_id,"values":_vm.$database.projects},model:{value:(_vm.params.project_id),callback:function ($$v) {_vm.$set(_vm.params, "project_id", $$v)},expression:"params.project_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('purchase discount record'),"details":[
            { title: _vm.$t('Customer Name'), value: _vm.customerName },
            { title: _vm.$t('User Name'), value: _vm.userName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('There are no data'),"emptyTableSubText":_vm.$t('Try adding some items and Try again'),"cloumns":[
            {
              column: 'code',
              link: '/purchaseDiscounts',
              title: _vm.$t('Code'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'date',
              title: _vm.$t('Payment Date'),
              type: 'text',
              sort: true
            },
            { column: 'customer', title: _vm.$t('Customer'), type: 'text', sort: true },
            { column: 'user', title: _vm.$t('User'), type: 'text', sort: true },
            { column: 'safe', title: _vm.$t('Safe'), type: 'text', sort: true },
            {
              column: 'paymentMethod',
              title: _vm.$t('Payment Method'),
              type: 'text',
              sort: true
            },
            { column: 'stat', title: _vm.$t('Status'), type: 'text', sort: true },
            {
              column: 'cost',
              title: _vm.$t('Cost'),
              type: 'text',
              sort: true
            }
          ],"footer":[
            { colspan: 6, value: _vm.$t('Total') },
            { colspan: 1, value: _vm.cost }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }