<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a>
                    <strong># {{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('Edit')"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="card card-body p-5 invoice-show">
          <div class="row">
            <div class="col-12">
              <StoresOrders :item="item" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">#{{ item.code }}</h4>
          </div>
          <div class="card-body">
            <p>
              {{ $t('Created in') }} {{ item.created }}, (<small
                >{{ $t('last update') }} {{ item.updated }}</small
              >)
            </p>
            <p>
              <strong>{{ $t('Administrator') }}</strong> :
              {{ item.user ? item.user.name : '--' }}
            </p>
            <p>
              <strong>{{ $t('The store') }}</strong> :
              {{ item.store ? item.store.name : '--' }}
            </p>
          </div>
        </div>
        <showFile />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'
import StoresOrders from '@/print/components/storesOrders.vue'

export default {
  data() {
    return {
      path: '/storesOrders',
      item: {},
      salesPayments: {},
      storesHistories: {},
      salesPaymentForm: false,
      addId: this.$route.params.id,
      amount: 0,
      type: 'show',
      loading: true,
      itemsCount: 0,

      printInvoice: false,
      site: localStorage.getItem('site')
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.salesPayments = this.item.sales_payments
        this.storesHistories = this.item.stores_histories
        this.amount = this.item.rest
        this.item.quotationItems.forEach((item) => {
          this.itemsCount += item.quantity - 0
        })
        this.loading = false
      })
    },
    print() {
      this.$parent.printedStoresDeposit = this.item
    }
  },
  components: {
    showFile,
    StoresOrders
  }
}
</script>
