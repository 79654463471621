<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :image="item.image"
        :icon="'chart-line'"
        :title="item.code"
        :buttons="[
          {
            title: $t('Edit'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $checkRole('manufacturing_operations_edit')
          }
        ]"
      />

      <showCard
        class="col-12"
        :title="$t('Basic information')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'user',
              title: $t('Manufacturing code'),
              value: item.code
            },
            {
              icon: 'calendar',
              title: $t('Start date'),
              value: $dateFormat(item.start_date)
            },
            {
              icon: 'money-bill',
              title: $t('Expected cost'),
              value: item.expected_cost + ' ' + $option.currency
            },
            {
              icon: 'money-bill',
              title: $t('Cost'),
              value: item.cost + ' ' + $option.currency
            },
            {
              icon: 'dollar-sign',
              title: $t('earning'),
              value: item.price - item.cost + ' ' + $option.currency
            }
          ],
          scondCloum: [
            {
              icon: 'toggle-on',
              title: $t('ProjectStatus'),
              value: item.stat == 1 ? $t('effective') : $t('Ineffective')
            },
            {
              icon: 'calendar',
              title: $t('End date'),
              value: $dateFormat(item.end_date)
            },
            {
              icon: 'money-bill-wave',
              title: $t('Expected price'),
              value: item.expected_price + ' ' + $option.currency
            },
            {
              icon: 'money-bill',
              title: $t('Price'),
              value: item.price + ' ' + $option.currency
            },
            {
              icon: 'dollar-sign',
              title: $t('Earning percentage'),
              value: (((item.price - item.cost) / item.price) * 100).toFixed(1) + ' %'
            }
          ]
        }"
      />
    </div>
    <showFile />
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'
import showHeader from '@/elements/show/showHeader.vue'
import showCard from '@/elements/show/showCard.vue'
import showBoxs from '@/elements/show/showBoxs.vue'

export default {
  data() {
    return {
      path: '/manufacturingOperations',
      loaded: false,
      item: {}
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      var item = this.$database.projects.find((el) => el.id == this.$route.params.id)
      if (item) {
        this.item = item
        this.item.start_date = new Date(item.start_date * 1000)
          .toISOString('en-US', { timeZone: 'Africa/Cairo' })
          .slice(0, 10)
      }
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.loaded = true
      })
    }
  },
  components: {
    showHeader,
    showCard,
    showBoxs,

    showFile
  }
}
</script>
