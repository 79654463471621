<template>
  <div class="form-group row input-control">
    <!-- Label -->
    <label class="col-md-4" :for="inputId">{{ title }}</label>
    <!-- Input -->
    <div class="col-md-7 add-input">
      <input
        :type="typeof type !== 'undefined' ? type : 'text'"
        class="form-control"
        :class="{ 'is-invalid': errors.length }"
        @input="$emit('input', $event.target.value)"
        :value="value"
        :disabled="disabled"
        :id="inputId"
      />
      <span v-if="after">{{ after }}</span>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-7">
      <div class="invalid-feedback d-block">
        <ul class="list-unstyled">
          <li v-for="(error, index) in errors" :key="index">{{ $t(error) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputId: Math.random().toString(36).substring(7)
    }
  },
  props: ['type', 'title', 'disabled', 'errors', 'after', 'value']
}
</script>
