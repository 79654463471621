var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Layout,[_c(_setup.PageHeader,{attrs:{"title":_vm.$t('Invoice status'),"description":_vm.$t('From Here You Can Control The Statuses Of Your Invoices')}}),_c(_setup.indexTable,{attrs:{"referenceId":_setup.referenceId,"addLink":true ? '/invoiceStatus/create' : '',"loading":_setup.loading,"error":_setup.error,"deleteId":_setup.deleteId,"page":_setup.params.page,"totalPages":_setup.totalPages,"perPage":_setup.params.perPage,"items":_setup.items,"searchText":_vm.$t('Search For a case'),"columnHeaders":[
      {
        sort: 'name',
        title: _vm.$t('Status Name')
      },

      {
        sort: 'stat',
        title: _vm.$t('The Status')
      },

      {
        title: _vm.$t('Actions')
      }
    ]},on:{"sortItems":_setup.sortItems,"closeDelete":_setup.closeDelete,"deleteItem":_setup.deleteItem,"changePage":_setup.changePage,"changeSearch":_setup.changeSearch,"changePerPage":_setup.changePerPage}},[_c('tbody',{staticClass:"list"},_vm._l((_setup.items),function(item,index){return _c('tr',{key:index,class:{ active: item.ShowSitting },on:{"click":function($event){return _setup.toggleSetting(index)}}},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[(item.stat)?_c('div',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.$t('Active')))]):_c('div',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(_vm.$t('Inactive')))])]),_c('td',{staticClass:"text-right edit-td"},[_c('div',{staticClass:"dropdown",class:{ show: item.showOptions }},[_c('i',{staticClass:"far fa-ellipsis-h",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":item.showOptions},on:{"click":function($event){return _setup.toggleOptions(index, $event)}}}),_c('div',{staticClass:"dropdown-menu",class:{ show: item.showOptions },style:('top: ' + item.top + 'px; left: ' + item.left + 'px'),attrs:{"aria-labelledby":"dropdownMenuButton"}},[(!item.ofline)?[(true)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":'invoiceStatus/' + item.id + '/edit'}},[_c('i',{staticClass:"far fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]):_vm._e(),(true)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _setup.sureDelete(index)}}},[_c('i',{staticClass:"far fa-trash"}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")]):_vm._e()]:[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _setup.sureDelete(index)}}},[_c('i',{staticClass:"far fa-trash"}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])]],2)])])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }