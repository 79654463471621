<template>
  <Layout>
    <!-- page header component -->
    <PageHeader
      :title="$t('Customers')"
      :description="$t('From here you can edit your customers details')"
    />
    <!-- index table component-->
    <indexTable
      :referenceId="referenceId"
      :addLink="$checkRole('customers_add') ? '/customers/create' : ''"
      :loading="loading"
      :error="error"
      :deleteId="deleteId"
      :page="params.page"
      :totalPages="totalPages"
      :perPage="params.perPage"
      :items="items"
      :searchText="$t('Search for a customer')"
      :columnHeaders="[
        { sort: 'id', title: $t('Code') },
        {
          sort: 'name',
          title: $t('Customer name')
        },
        {
          sort: 'tax_number',
          title: $t('Customer code')
        },
        {
          sort: 'mobile',
          title: $t('Phone number')
        },
        {
          sort: 'balance',
          title: $t('Balance')
        },
        {
          sort: 'stat',
          title: $t('Active')
        },
        {
          title: $t('Actions')
        }
      ]"
      @sortItems="sortItems"
      @closeDelete="closeDelete"
      @deleteItem="deleteItem"
      @changePage="changePage"
      @changeSearch="changeSearch"
      @changePerPage="changePerPage"
    >
      <tbody class="list">
        <tr
          v-for="(item, index) in items"
          :key="index"
          @click="toggleSetting(index)"
          :class="{ active: item.ShowSitting }"
        >
          <td>
            <router-link :to="'/customers/' + item.id"> # {{ item.id }} </router-link>
          </td>
          <td>
            <router-link :to="'/customers/' + item.id">
              {{ item.name }}
            </router-link>
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Customer code') }}</strong>
            {{ item.tax_number ?? '--' }}
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Phone number') }}</strong>
            {{ item.mobile ?? '--' }}
          </td>
          <td>
            <strong class="mobile-title">{{ $t('Balance') }}</strong>
            {{ item.balance }}
          </td>
          <td>
            <div class="badge badge-success" v-if="item.stat">{{ $t('Active') }}</div>
            <div class="badge badge-danger" v-else>{{ $t('Inactive') }}</div>
          </td>
          <td class="text-right edit-td">
            <div class="dropdown" :class="{ show: item.showOptions }">
              <i
                class="far fa-ellipsis-h"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                :aria-expanded="item.showOptions"
                @click="toggleOptions(index, $event)"
              ></i>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                :class="{ show: item.showOptions }"
                :style="'top: ' + item.top + 'px; left: ' + item.left + 'px'"
              >
                <template v-if="!item.ofline">
                  <router-link :to="'customers/' + item.id" class="dropdown-item">
                    <i class="far fa-search"></i>
                    {{ $t('Show') }}
                  </router-link>
                  <router-link
                    :to="'customers/' + item.id + '/edit'"
                    class="dropdown-item"
                    v-if="$checkRole('customers_edit')"
                  >
                    <i class="far fa-edit"></i>
                    {{ $t('Edit') }}
                  </router-link>

                  <router-link
                    :to="'salesPayments/create?customer_id=' + item.id"
                    class="dropdown-item"
                    v-if="$checkRole('sales_payments_add')"
                  >
                    <i class="far fa-dollar-sign"></i>
                    {{ $t('Add payment') }}
                  </router-link>

                  <router-link
                    :to="'invoices/create?customer_id=' + item.id"
                    class="dropdown-item"
                    v-if="$checkRole('invoices_add')"
                  >
                    <i class="far fa-file-invoice-dollar"></i>
                    {{ $t('Add invoice') }}
                  </router-link>

                  <a
                    @click="sureDelete(index)"
                    class="dropdown-item"
                    v-if="$checkRole('customers_delete')"
                  >
                    <i class="far fa-trash"></i>
                    {{ $t('Delete') }}
                  </a>
                </template>
                <template v-else>
                  <a @click="sureDelete(index)" class="dropdown-item">
                    <i class="far fa-trash"></i>
                    {{ $t('Delete') }}
                  </a>
                </template>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </indexTable>
  </Layout>
</template>

<script setup>
// layout component
import Layout from '@/layouts/main.vue'
// page components
import PageHeader from '@/components/pageHeader'
import indexTable from '@/components/table/index'

// helpers functions
import tableHock from '@/helpers/tableHock'

const {
  referenceId,
  items,
  totalPages,
  loading,
  error,
  deleteId,
  params,
  sortItems,
  sureDelete,
  toggleOptions,
  toggleSetting,
  closeDelete,
  changePerPage,
  deleteItem,
  changePage,
  changeSearch
} = tableHock({ path: 'customers', localData: true })
</script>
