<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="sm-stop-edit" v-if="loading"></div>
        <div class="modal-card card">
          <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
              {{ deleteText ? deleteText.attention : $t('Attention') }}
            </h4>
          </div>
          <div class="card-body">
            <p>
              {{
                deleteText
                  ? deleteText.areYouReallySureToDeleteTheItem
                  : $t('Are you really sure to delete the item')
              }}
            </p>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="close()">
                  <i class="far fa-times"></i>
                  {{ $t('close') }}
                </button>
                <button @click="deleteItem()" class="btn btn-dark" v-if="!loading">
                  <i class="far fa-check"></i>
                  {{ $t('confirm') }}
                </button>
                <button class="btn btn-dark" v-if="loading">
                  {{ $t('loading') }} <span class="loading-s1 sm-j">.</span
                  ><span class="loading-s2 sm-j">.</span><span class="loading-s3 sm-j">.</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false
    }
  },
  methods: {
    close() {
      this.$emit('closeDelete')
    },
    deleteItem() {
      this.$emit('deleteItem')
      this.loading = true
    }
  },
  props: ['deleteText']
}
</script>
