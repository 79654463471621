var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"print-model"},[(!_vm.loading)?_c('div',{ref:"printMe",attrs:{"id":"printMe"}},[(_vm.options.print_type == 4)?_c('div',{staticClass:"design-1-container"},[_c('div',{staticClass:"header"},[(_vm.options.image != null)?_c('img',{staticClass:"companyImage",attrs:{"src":_vm.logo}}):_vm._e(),_c('div',{staticClass:"companydetails"},[_c('h1',[_vm._v(_vm._s(_vm.options.name))]),(_vm.options.telephone)?_c('p',[_vm._v("الهاتف: "+_vm._s(_vm.options.telephone))]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.options.country))]),_c('p',[_vm._v(" "+_vm._s(_vm.options.address1)+" "+_vm._s(_vm.options.address1 && _vm.options.city ? ',' : '')+" "+_vm._s(_vm.options.city)+" ")])])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"invoive-detail"},[_c('div',{staticClass:"invoice-num"},[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.item.code))])])]),_c('div',{staticClass:"cleint border"},[_vm._m(1),(_vm.item.client_type == 0)?_c('div',{},[_c('strong',[_vm._v(_vm._s(_vm.item.customer.name))])]):_vm._e(),(_vm.item.client_type == 1)?_c('div',{staticClass:"cleint-info"},[_c('strong',[_vm._v(_vm._s(_vm.item.supplier.name))])]):_vm._e()])]),_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("أمر تشغيل")]),_c('div',{staticClass:"container-of-table",staticStyle:{"margin-top":"100px"}},[_c('table',{staticClass:"mytable"},[_vm._m(2),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.item.quotationItems[_vm.index].product_name))]),_c('td',[_vm._v(" "+_vm._s(parseFloat(_vm.item.quotationItems[_vm.index].quantity).toFixed(1))+" ")])]),_c('tr',[_c('td',{attrs:{"colspan":1}},[(_vm.item.quotationItems[_vm.index].image)?_c('img',{staticStyle:{"max-width":"250px","border":"1px solid rgb(46, 44, 44)","padding":"4px"},attrs:{"src":_vm.$linkGenerator(
                      '/companiesUploads/mina/products/' + _vm.item.quotationItems[_vm.index].image,
                      true
                    )}}):_vm._e()]),_c('td',{staticClass:"td-parent",attrs:{"colspan":3},domProps:{"innerHTML":_vm._s(_vm.item.quotationItems[_vm.index].description)}})])])])])]):_vm._e(),_c('p',{staticClass:"pioneer-data"},[_vm._v("تم الإنشاء بواسطة Pioneer للبرمجيات 01019654321")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_vm._v("رقم الفاتورة"),_c('br'),_vm._v("Invoice No")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"cleint-name"},[_vm._v("العميل"),_c('br'),_vm._v("Client")])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("الصنف"),_c('br'),_vm._v("Item")]),_c('td',[_vm._v("الكمية"),_c('br'),_vm._v("Qty")])])])
}]

export { render, staticRenderFns }