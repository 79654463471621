<template>
  <div class="form-group row col-md-6'">
    <!-- Label -->
    <label class="col-md-4" :for="inputId">{{ title }}</label>
    <!-- Input -->
    <div class="col-md-7">
      <select
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.length }"
        @input="$emit('input', $event.target.value)"
        :disabled="disabled"
        :id="inputId"
        :value="value"
      >
        <template v-for="value in values">
          <option :key="value.id" :value="value.value">
            {{ value.name }}
          </option>
        </template>
      </select>
      <div class="invalid-feedback">
        <ul class="list-unstyled">
          <li v-for="(error, index) in errors" :key="index">{{ $t(error) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputId: Math.random().toString(36).substring(7)
    }
  },
  props: ['title', 'disabled', 'errors', 'values', 'value']
}
</script>
