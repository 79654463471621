var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('PageHeader',{attrs:{"title":_vm.$t('Reports'),"description":'طلبات الشراء حسب الفاتورة .'}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('Reports options'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Supplier'),"plaseholder":_vm.$t('Choose supplier'),"show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.$database.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('user'),"plaseholder":_vm.$t('Choose user'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.$database.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('Store'),"plaseholder":_vm.$t('Choose store'),"show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"طلبات الشراء حسب الفاتورة ","details":[
            { title: 'اسم المورد', value: _vm.supplierName },
            { title: _vm.$t('Username'), value: _vm.userName },
            { title: _vm.$t('Store'), value: _vm.storeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('there are no data at the moment'),"emptyTableSubText":_vm.$t('Try adding some items and try again'),"cloumns":[
            {
              column: 'code',
              link: '/bills',
              title: _vm.$t('Code'),
              type: 'reportMainLink',
              sort: true
            },
            { column: 'date', title: _vm.$t('Date'), type: 'text', sort: true },
            { column: 'supplier', title: 'المورد', type: 'text', sort: true },
            { column: 'user', title: _vm.$t('User'), type: 'text', sort: true },
            {
              column: 'subtotal',
              title: _vm.$t('Total'),
              type: 'text',
              sort: true
            },
            {
              column: 'edit',
              title: _vm.$t('Edit'),
              type: 'text',
              sort: true,
              after: _vm.$option.currency
            },
            {
              column: 'delivery',
              title: _vm.$t('Shipping fees'),
              type: 'text',
              sort: true,
              after: _vm.$option.currency
            },
            {
              column: 'tax',
              title: _vm.$t('Taxes'),
              type: 'text',
              sort: true,
              after: _vm.$option.currency
            },
            {
              column: 'discount',
              title: _vm.$t('Discount'),
              type: 'text',
              sort: true,
              after: _vm.$option.currency
            },
            {
              column: 'total',
              title: _vm.$t('Total'),
              type: 'text',
              sort: true,
              after: _vm.$option.currency
            }
          ],"footer":[
            { colspan: 1, value: _vm.$t('Total') },
            { colspan: 3, value: _vm.invoicesCount },
            { colspan: 1, value: _vm.subtotal },
            { colspan: 1, value: _vm.edit },
            { colspan: 1, value: _vm.delivery },
            { colspan: 1, value: _vm.tax },
            { colspan: 1, value: _vm.discount },
            { colspan: 1, value: _vm.total }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }