<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('Add tax payment')"
          :description="$t('From Here You Can Create a New tax')"
          v-if="$route.name == 'taxPaymentsCreate'"
        />
        <addHeader
          :title="$t('Expense Adjustment')"
          :description="$t('From here you can modify the tax information')"
          v-if="$route.name == 'taxPaymentsEdit'"
        />
        <div class="mb-4 row">
          <connectionInput
            :title="$t('Numbering of allowances')"
            :group="$t('Groups')"
            v-model="item.invoice_group"
            :disabled="$checkRole('expenses_edit_item') ? false : true"
            :hasErorr="errors.invoice_group"
            :error="$t('This field is required')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'taxPaymentsCreate'"
          />
          <formInput
            :title="$t('Allowance code')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'taxPaymentsEdit'"
            maxlength="255"
          />
          <subFileInput
            :title="$t('Invoice file')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
            :hasErorr="errors.file"
            :error="$t('Choose a suitable file')"
          />
          <formInput :title="$t('Reference')" v-model="item.refrance" maxlength="255" />
          <formInput :title="$t('paidFor')" v-model="item.cash_to" maxlength="255" />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{ $t('Date') }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('Choose time and date')"
                  :button-now-translation="$t('Now')"
                  v-model="item.date"
                  :disabled="$checkRole('expenses_edit_item') ? false : true"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <selectInput
            :title="$t('Situation')"
            v-model="item.status"
            :values="[
              { name: $t('Draft'), value: 0 },
              { name: $t('Approved'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('Accounts information')"
            :description="$t('Account information details')"
          />

          <monyInput
            :title="$t('Cost')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('Cost must be greater than')"
          />

          <searchGroupInput
            :type="'safe'"
            :values="$database.safes"
            :inputs="[{ show: 'name' }, { show: 'balance', moany: true }]"
          />

          <dvider :title="$t('Payment notes')" :description="$t('Payment notes details')" />

          <formTextarea :title="$t('That about')" v-model="item.description" />
          <formTextarea :title="$t('Note')" v-model="item.notes" />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'taxPaymentsCreate' && !$parent.stopEdit"
          >
            {{ $t('Add tax Payment') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'taxPaymentsEdit' && !$parent.stopEdit"
          >
            {{ $t('Edit tax Payment') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import subFileInput from '@/elements/add/form/subFileInput.vue'
import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  data() {
    return {
      path: '/taxPayments',
      item: {
        invoice_group: this.$option.tax_payment_group_id,
        safe_id: 0,
        date: '',
        type: 0,
        safe: {}
      },
      model: false,
      errors: {
        cost: false,
        safe_id: false,
        invoice_group: false
      },
      expensesSections: [],
      selectedExpensesSections: []
    }
  },
  mounted() {
    this.$updateDatabase([
      'invoiceGroups',
      'customers',
      'suppliers',
      'projects',
      'projectItems',
      'safes',
      'paymentMethods',
      'taxes',
      'expenseSections'
    ])

    if (this.$route.name == 'taxPaymentsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    }

    if (this.$route.name == 'taxPaymentsCreate') {
      var params = this.$getParams()
      if (params.safe_id) {
        var safe = this.$database.safes.find((el) => el.id == params.safe_id)
        this.item.safe_id = params.safe_id
        this.item.safe = safe
      } else {
        if (this.$database.safes.length == 1) {
          this.item.safe_id = this.$database.safes[0].id
          this.item.safe = this.$database.safes[0]
        }
      }

      this.item.date = this.$nowDate()
    }
    this.expensesSections = this.$database.expenseSections.filter(
      (el) => el.expense_section_id == 0
    )

    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  methods: {
    getItem() {
      axios.get(this.$linkGenerator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.$parent.stopEdit = false
        this.item.date = this.$dateFormat(this.item.date, true)
      })
    },
    saveItem() {
      var error = 0
      if (typeof this.item.cost === 'undefined' || this.item.cost == '' || this.item.cost < 1) {
        error = 1
        this.errors.cost = true
      }
      if (this.item.cost > this.item.safe.balance) {
        error = 1
      }
      if (
        this.item.type == 0 &&
        (typeof this.item.safe_id === 'undefined' ||
          this.item.safe_id == '' ||
          this.item.safe_id == 0)
      ) {
        error = 1
        this.errors.safe_id = true
      }
      if (
        (typeof this.item.invoice_group === 'undefined' ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == '' ||
          this.item.invoice_group == null) &&
        this.$route.name == 'taxPaymentsCreate'
      ) {
        error = 1
        this.errors.invoice_group = true
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == 'taxPaymentsCreate') {
          this.$toast.success(this.$t('tax Successfully Added'))
          this.$addToLocalDatabase(this.item, this.path.substring(1))

          var safe = this.$database.safes.find((el) => el.id == this.item.safe_id)
          if (safe && this.item.type == 0) {
            safe.balance = parseFloat(safe.balance) - parseFloat(this.item.cost)
          }
          localStorage.database = JSON.stringify(this.$database)

          this.$router.go(-1)
          this.$parent.stopEdit = false
        } else if (this.$route.name == 'taxPaymentsEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGenerator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              if (response.data == 'safe error') {
                this.$toast.error(this.$t('The employee is not tied to a safe'))
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              } else if (response.data == 'money error') {
                this.$toast.error(this.$t('The amount is not in the safe'))
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              } else {
                this.$toast.success(this.$t('Expense has been modified successfully'))
                this.$router.go(-1)
                this.$parent.stopEdit = false
                return response
              }
            })
        }
      } else {
        this.$toast.error(
          this.item.cost > this.item.safe.balance
            ? this.$t('The balance of the safe is not enough')
            : this.$t('Please make sure of the input')
        )
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  },
  watch: {
    'item.expense_section_id': function (val) {
      if (val == 0) {
        this.selectedExpensesSections = []
      } else {
        this.selectedExpensesSections = this.$database.expenseSections.filter(
          (el) => el.expense_section_id == val
        )
      }
    }
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    formTextarea,
    connectionInput,
    loading,
    selectInput,
    subFileInput,

    VueCtkDateTimePicker,
    searchGroupInput
  }
}
</script>
