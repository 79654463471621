import { ref, onMounted, onBeforeUnmount } from 'vue'

export function useClickOutside() {
  const isOutside = ref(false)
  const elementRef = ref(null)

  const handleClickOutside = (event) => {
    if (elementRef.value && !elementRef.value.contains(event.target)) {
      isOutside.value = true
    } else {
      isOutside.value = false
    }
  }

  onMounted(() => document.addEventListener('click', handleClickOutside))

  onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))

  return { elementRef, isOutside }
}
