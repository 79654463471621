<template>
  <thead>
    <tr>
      <template v-for="column in columnHeaders">
        <TableTh :text="column.title" :sort="column.sort" @sortItems="sortItems" />
      </template>
    </tr>
  </thead>
</template>
<script>
import TableTh from './tableTh.vue'

export default {
  components: { TableTh },
  props: ['columnHeaders'],
  methods: {
    sortItems(name) {
      this.$emit('sortItems', name)
    }
  }
}
</script>
