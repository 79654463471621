var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'box-open',"title":_vm.item.name,"subData":[
        {
          title: _vm.$t('materials.show.sellingPrice'),
          value: this.$moneyFormat(_vm.item.price)
        }
      ],"buttons":[
        {
          title: _vm.$t('materials.show.modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('products_edit')
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('materials.show.Background'),"cloumns":{
        firstCloum: [
          {
            icon: 'box-open',
            title: _vm.$t('materials.show.TheNameOfTheMaterial'),
            value: _vm.item.name
          },
          {
            icon: 'layer-group',
            title: _vm.$t('materials.show.RawMaterialRating'),
            value: _vm.item.section,
            type: 'link',
            to: 'section',
            link: false
          },
          {
            icon: 'terminal',
            title: _vm.$t('materials.show.RawMaterialCode'),
            value: _vm.item.code
          }
        ],
        scondCloum: [
          {
            icon: 'gift',
            title: _vm.$t('materials.show.TypeOfMaterial'),
            value: _vm.item.type == 0 ? _vm.$t('materials.show.product') : _vm.$t('materials.show.service')
          },
          {
            icon: 'user-tie',
            title: _vm.$t('materials.show.supplier'),
            value: _vm.item.supplier,
            type: 'link',
            to: 'supplier',
            link: true
          },
          {
            icon: 'barcode',
            title: _vm.$t('materials.show.Barcode'),
            value: _vm.item.barcode
          }
        ]
      }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('materials.show.AccountsInformation'),"cloumns":{
        firstCloum: [
          {
            icon: 'user-tie',
            title: _vm.$t('materials.show.supplier'),
            value: _vm.item.supplier,
            type: 'link',
            to: 'supplier',
            link: true
          }
        ],
        scondCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('materials.show.sellingPrice'),
            value: this.$moneyFormat(_vm.item.price)
          }
        ]
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }