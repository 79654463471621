<template>
  <div class="modal fade show cash-form">
    <div class="modal-dialog modal-dialog-centered big-model">
      <div class="modal-content">
        <div class="modal-card card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t('Order details') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row" v-if="options.change_customer">
              <customerSearchInput class="col-6" :item="item" @updateItem="updateItem" />
              <customerPhoneInput class="col-6" :item="item" @updateItem="updateItem" />
            </div>
            <hr v-if="options.show_rest" />
            <div class="row" v-if="options.show_rest">
              <div class="col-6">
                <div class="form-group row">
                  <label class="col-sm-3"> {{ $t('Total') }}</label>
                  <div class="col-md-7 add-input">
                    <input type="number" disabled class="form-control" :value="item.total" />
                    <span>{{ $option.currency }}</span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group row">
                  <label class="col-sm-3"> {{ $t('cash') }}</label>
                  <div class="col-md-7 add-input">
                    <input type="number" class="form-control" v-model="cash" id="cashInput" />
                    <span>{{ $option.currency }}</span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group row">
                  <label class="col-sm-3"> {{ $t('Rest') }}</label>
                  <div class="col-md-7 add-input">
                    <input
                      type="number"
                      disabled
                      class="form-control"
                      :value="item.cash ? item.cash - item.total : 0"
                    />
                    <span>{{ $option.currency }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <button class="btn btn-outline-danger" @click="$emit('closeCash')">
                  <i class="far fa-times"></i>
                  {{ $t('Close') }}
                </button>
                <button @click="checkOut()" class="btn btn-dark">
                  <i class="far fa-save"></i>
                  {{ $t('Pay') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed, onMounted } from 'vue'
import customerSearchInput from '../inputs/customerSearchInput'
import customerPhoneInput from '../inputs/customerPhoneInput'

// Focus on cash input
onMounted(() => document.getElementById('cashInput')?.focus())

// Checkout logic
const checkOut = () => {
  emits('checkOut')
  emits('closeCash')
}

const updateItem = (newItem) => emits('updateItem', newItem)

// Computed inputs
const cash = computed({
  get: () => props.item.cash,
  set: (value) => emits('updateItem', { ...props.item, cash: value })
})

// Define props
const props = defineProps(['options', 'item'])
const emits = defineEmits(['checkOut', 'closeCash', 'updateItem'])
</script>
