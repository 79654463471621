import pointOfSalesIndex from '@/pages/pointOfSales/pointOfSales/index.vue'
import pointOfSalesForm from '@/pages/pointOfSales/pointOfSales/form.vue'

import pointOfSalesShow from '@/pages/pointOfSales/salesApp/index.vue'
import pointOfSalesInvoices from '@/pages/pointOfSales/salesApp/invoices.vue'
import pointOfSalesExpenses from '@/pages/pointOfSales/salesApp/expenses.vue'

const routes = [
  {
    path: 'pointOfSales',
    name: 'pointOfSales',
    component: pointOfSalesIndex
  },
  {
    path: 'pointOfSales/create',
    name: 'pointOfSalesCreate',
    component: pointOfSalesForm
  },
  {
    path: 'pointOfSales/:id/edit',
    name: 'pointOfSalesEdit',
    component: pointOfSalesForm
  },
  {
    path: 'pointOfSales/:id',
    name: 'pointOfSalesShow',
    component: pointOfSalesShow
  },
  {
    path: 'pointOfSales/:id/invoices',
    name: 'pointOfSalesInvoicesSingle',
    component: pointOfSalesInvoices
  },
  {
    path: 'pointOfSales/:id/expenses',
    name: 'pointOfSalesExpenses',
    component: pointOfSalesExpenses
  }
]

export default routes
