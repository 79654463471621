<template>
  <div class="row container-fluid">
    <div class="col-12">
      <PageHeader
        :title="$t('partners')"
        :description="$t('From here you can control your partners reports')"
      />
    </div>

    <!-- تقارير الشركاء -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('partners')"
      :icon="'fa-handshake'"
      v-if="
        ($user.role.available_reports.find(
          (el) => el.id == 34 || el.id == 35 || el.id == 36 || el.id == 37
        ) ||
          $user.admin) &&
        $site.partners_allow
      "
      :reports="[
        {
          name: $t('Total Partner Account'),
          link: './partnersTotalAccounts',
          role: $user.role.available_reports.find((el) => el.id == 34) || $user.admin
        },
        {
          name: $t('Partner Account Details'),
          link: '/partnersDetailsAccounts',
          role: $user.role.available_reports.find((el) => el.id == 35) || $user.admin
        },
        {
          name: $t('Partners Assets'),
          link: '/partnersBalance',
          role: $user.role.available_reports.find((el) => el.id == 36) || $user.admin
        },
        {
          name: $t('Partner Guide'),
          link: '/partnersGuide',
          role: $user.role.available_reports.find((el) => el.id == 37) || $user.admin
        },
        {
          name: $t('partner Movement'),
          link: '/partnerMovement',
          role: $user.role.available_reports.find((el) => el.id == 37) || $user.admin
        }
      ]"
    />
    <!-- تقارير عمليات الشركاء -->

    <reportsCard
      class="col-md-6"
      :cardTitle="$t('Partners Operations')"
      :icon="'fa-handshake'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 31 || el.id == 32) || $user.admin) &&
        ($site.partnersDeposits_allow ||
          $site.partnersWithdrawals_allow ||
          $site.partnersSettlements_allow ||
          $site.partnersProfits_allow)
      "
      :reports="[
        {
          name: $t('Partner Deposits History'),
          link: './partnerDepositsHistory',
          role: $user.role.available_reports.find((el) => el.id == 31) || $user.admin
        },
        {
          name: $t('Partner Withdrawals History'),
          link: '/partnerWithdrawalsHistory',
          role: $user.role.available_reports.find((el) => el.id == 32) || $user.admin
        }
      ]"
    />
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import PageHeader from '@/components/pageHeader'
export default {
  components: {
    reportsCard,
    PageHeader
  }
}
</script>
