import Vue from 'vue'

const initItem = (item) => {
  if (item.date) item.date = Vue.prototype.$dateFormat(item.date, '')
  if (item.expiry_date) item.expiry_date = Vue.prototype.$dateFormat(item.expiry_date, '')
  if (item.created_date) item.created_date = Vue.prototype.$dateFormat(item.created_date, '')
  if (item.start_date) item.start_date = Vue.prototype.$dateFormat(item.start_date, '')
  if (item.end_date) item.end_date = Vue.prototype.$dateFormat(item.end_date, '')
  if (item.out_date) item.out_date = Vue.prototype.$dateFormat(item.out_date, '')

  return item
}

export default initItem
