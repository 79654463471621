export const setParams = (params) => {
  var url = new URL(window.location.href)
  var search_params = url.searchParams
  Object.keys(params).forEach(function (key) {
    search_params.set(key, params[key])
  })
  url.search = search_params.toString()
  window.history.pushState({}, '', window.location.href.split('?')[0] + url.search)
}

export const getParams = () => {
  var searchParams = new URLSearchParams(window.location.href.split('?')[1]).entries()
  let result = {}
  for (let entry of searchParams) {
    result[entry[0]] = entry[1]
  }

  result.per_page = result.per_page ? result.per_page : 15
  result.page = result.page ? result.page : 1

  return result
}
