import Vue from 'vue'
const roles = {
  users_allow: ['users_show', 'users_add', 'users_edit', 'users_delete'],
  customers_allow: ['customers_show', 'customers_add', 'customers_edit', 'customers_delete'],
  quotations_allow: [
    'quotations_show',
    'quotations_add',
    'quotations_edit',
    'quotations_edit_product',
    'quotations_delete'
  ],
  invoices_allow: [
    'invoices_show',
    'invoices_add',
    'invoices_edit',
    'invoices_delete',
    'invoices_edit_product'
  ],
  saleReturns_allow: [
    'sale_returns_show',
    'sale_returns_add',
    'sale_returns_edit',
    'sale_returns_edit_product',
    'sale_returns_delete'
  ],
  orders_allow: [
    'orders_show',
    'orders_add',
    'orders_edit',
    'orders_delete',
    'orders_allow_status',
    'orders_edit_date',
    'orders_edit_product',
    'purchase_orders_edit_product'
  ],
  suppliers_allow: ['suppliers_show', 'suppliers_add', 'suppliers_delete', 'suppliers_edit'],
  bills_allow: ['bills_show', 'bills_add', 'bills_edit', 'bills_delete', 'bills_edit_product'],
  purchaseReturns_allow: [
    'purchase_returns_show',
    'purchase_returns_add',
    'purchase_returns_edit',
    'purchase_returns_edit_product',
    'purchase_returns_delete'
  ],

  safes_allow: ['safes_add', 'safes_show', 'safes_edit', 'safes_delete'],
  safesDeposits_allow: [
    'safes_deposits_show',
    'safes_deposits_add',
    'safes_deposits_edit',
    'safes_deposits_delete'
  ],
  safesWithdrawals_allow: [
    'safes_withdrawals_show',
    'safes_withdrawals_add',
    'safes_withdrawals_edit',
    'safes_withdrawals_delete'
  ],
  safesTransfers_allow: [
    'safes_transfers_show',
    'safes_transfers_add',
    'safes_transfers_edit',
    'safes_transfers_delete'
  ],
  bankAccounts_allow: [
    'bank_accounts_show',
    'bank_accounts_add',
    'bank_accounts_edit',
    'bank_accounts_delete'
  ],
  salesPayments_allow: [
    'sales_payments_add',
    'sales_payments_show',
    'sales_payments_edit',
    'sales_payments_edit_item',
    'sales_payments_delete'
  ],
  salesInstallments_allow: [
    'sales_installments_show',
    'sales_installments_add',
    'sales_installments_edit',
    'sales_installments_delete'
  ],
  purchaseInstallments_allow: [
    'purchase_installments_show',
    'purchase_installments_add',
    'purchase_installments_edit',
    'purchase_installments_delete'
  ],
  purchasePayments_allow: [
    'purchase_payments_show',
    'purchase_payments_edit',
    'purchase_payments_edit_item',
    'purchase_payments_delete',
    'purchase_payments_add'
  ],
  taxpayments_allow: [
    'tax_payments_show',
    'tax_payments_add',
    'tax_payments_edit',
    'tax_payments_edit_item',
    'tax_payments_delete'
  ],
  expenses_allow: [
    'expenses_show',
    'expenses_edit',
    'expenses_delete',
    'expenses_add',
    'expenses_edit_item'
  ],
  stores_allow: [
    'stores_add',
    'stores_show',
    'stores_edit',
    'stores_orders_add',
    'stores_orders_edit_item',
    'stores_delete',
    'stores_orders_show',
    'stores_orders_delete',
    'products_add',
    'products_show',
    'customers_delete',
    'customers_edit'
  ],
  storesDeposits_allow: [
    'stores_deposits_show',
    'stores_deposits_add',
    'stores_deposits_edit',
    'store_deposit_edit_item',
    'stores_deposits_delete'
  ],
  storesWithdrawals_allow: [
    'stores_withdrawals_add',
    'stores_withdrawals_show',
    'stores_withdrawals_edit',
    'stores_withdrawals_edit_item',
    'store_withdrawals_edit_item',
    'stores_withdrawals_delete'
  ],

  storesTransfers_allow: [
    'stores_transfers_show',
    'stores_transfers_add',
    'stores_transfers_edit',
    'stores_transfers_delete'
  ],
  salesCheques_allow: [
    'sales_cheques_show',
    'sales_cheques_edit',
    'sales_cheques_edit_item',
    'sales_cheques_delete',
    'sales_cheques_add'
  ],
  purchaseCheques_allow: [
    'purchase_cheques_add',
    'purchase_cheques_show',
    'purchase_cheques_edit',
    ' purchase_cheques_edit_item',
    'purchase_cheques_delete'
  ],

  salesDiscounts_allow: [
    'sales_discounts_show',
    'sales_discounts_add',
    'sales_discounts_edit_item',
    'sales_discounts_edit',
    'sales_discounts_delete'
  ],
  purchaseDiscounts_allow: [
    'purchase_discounts_show',
    'purchase_discounts_add',
    'purchase_discounts_edit_item',
    'purchase_discounts_edit',
    'purchase_discounts_delete'
  ],

  employees_allow: ['employees_show', 'employees_add', 'employees_edit', 'employees_delete'],
  employeesSalaries_allow: [
    'employees_Salaries_show',
    'employees_salaries_add',
    'employees_salaries_edit',
    'employees_salaries_edit_item',
    'employees_salaries_delete'
  ],
  employeesIncentives_allow: [
    'employees_incentives_show',
    'employees_incentives_add',
    'employees_incentives_edit',
    'employees_incentives_edit_item',
    'employees_incentives_delete'
  ],
  employeesDeductions_allow: [
    'employees_deductions_show',
    'employees_deductions_add',
    'employees_deductions_edit',
    'employees_deductions_edit_item',
    'employees_deductions_delete'
  ],
  employeesWithdrawals_allow: [
    'employees_withdrawals_show',
    'employees_withdrawals_add',
    'employees_withdrawals_edit',
    'employees_withdrawals_edit_item',
    'employees_withdrawals_delete'
  ],
  partners_allow: ['partners_show', 'partners_add', 'partners_edit', 'partners_delete'],
  partnersDeposits_allow: [
    'partners_deposits_show',
    'partners_deposits_add',
    'partners_deposits_edit',
    'partners_deposits_delete',
    'partners_deposits_edit_item'
  ],
  partnersWithdrawals_allow: [
    'partners_withdrawals_show',
    'partners_withdrawals_add',
    'partners_withdrawals_edit',
    'partners_withdrawals_edit_item',
    'partners_withdrawals_delete'
  ],
  partnersSettlements_allow: [
    'partners_settlements_show',
    'partners_settlements_edit',
    'partners_settlements_edit_item',
    'partners_settlements_delete'
  ],
  partnersProfits_allow: [
    'partners_profits_show',
    'partners_profits_add',
    'partners_profits_edit',
    'partners_profits_edit_item',
    'partners_profits_delete'
  ],

  capitals_allow: ['capitals_show', 'capitals_add', 'capitals_edit', 'capitals_delete'],
  manufacturingModels_allow: [
    'manufacturing_models_show',
    'manufacturing_models_add',
    'manufacturing_models_delete',
    'manufacturing_models_edit',
    'manufacturing_operations_edit_item',
    'manufacturing_deposits_edit',
    'manufacturing_deposits_delete'
  ],
  manufacturingOperations_allow: [
    'manufacturing_operations_add',
    'manufacturing_operations_show',
    'manufacturing_operations_edit',
    'manufacturing_operations_edit_item',
    'manufacturing_operations_delete'
  ],
  manufacturingDeposits_allow: [
    'manufacturing_deposits_add',
    'manufacturing_deposits_show',
    'manufacturing_deposits_edit',
    'manufacturing_deposits_edit_item',
    'manufacturing_deposits_delete'
  ],
  manufacturingWithdrawals_allow: [
    'manufacturing_withdrawals_add',
    'manufacturing_withdrawals_show',
    'manufacturing_withdrawals_edit',
    'manufacturing_withdrawals_edit_item',
    'manufacturing_withdrawals_delete'
  ],
  manufacturingTransfers_allow: [
    'manufacturing_transfers_add',
    'manufacturing_transfers_show',
    'manufacturing_transfers_edit',
    'manufacturing_transfers_edit_item',
    'manufacturing_transfers_delete'
  ],
  cities_allow: [
    'cities_show',
    'pickupLocations_add',
    'pickupLocations_edit',
    'pickup_locations_edit',
    'pickupLocations_delete',
    'shippingOperations_edit_product',
    'shippingOperations_add',
    'shippingOperations_edit',
    'shippingOperations_delete'
  ],
  pointOfSales_allow: [
    'point_of_sales_show',
    'point_of_sales_add',
    'point_of_sales_edit',
    'point_of_sales_delete',
    'point_of_sales_show_balance'
  ],
  accountsDeposits_allow: [
    'accounts_deposits_show',
    'accounts_deposits_add',
    'accounts_deposits_edit',
    'accounts_deposits_delete'
  ],
  accountsWithdrawals_allow: [
    'accounts_withdrawals_show',
    'accounts_withdrawals_add',
    'accounts_withdrawals_edit',
    'accounts_withdrawals_delete'
  ],
  reports_allow: [
    'reports_show',
    'accounting_show',
    'projects_withdrawals_delete',
    'projects_withdrawals_edit',
    'projects_withdrawals_add'
  ],
  projectsWithdrawals_allow: [
    'projects_withdrawals_show',
    'projects_withdrawals_add',
    'projects_withdrawals_edit_item',
    'projects_withdrawals_edit',
    'projects_withdrawals_delete',
    'projects_withdrawals_show_allow'
  ],
  projectsDeposits_allow: [
    'projects_deposits_show',
    'projects_deposits_add',
    'projects_deposits_edit_item',
    'projects_deposits_edit',
    'projects_deposits_delete',
    'projects_deposits_show_allow'
  ],
  companies_allow: ['companies_show', 'companies_add', 'companies_edit', 'companies_delete'],
  pickupLocationsDeposits_allow: ['pickupLocations_deposits_add'],
  pickupLocationsWithdrawals_allow: ['pickupLocations_withdrawals_add'],
  pickupLocationsTransfers_allow: ['pickupLocations_transfers_add'],
  global: [
    'options_show',
    'profile_edit',
    'roles_show',
    'signatures_edit',
    'signatures_delete',
    'purchase_returns_edit_product',
    'purchase_orders_edit_product',
    'roles_add',
    'roles_edit',
    'roles_delete',
    'users_edit',
    'users_delete',
    'users_add',
    'users_show',
    'products_delete',
    'products_add',
    'products_edit',
    'incomes_add',
    'incomes_edit',
    'incomes_delete',
    'partners_settlements_add',
    'reports_earning_show',
    'buckup_allow',
    'pickupLocations_transfers_add',
    'pickupLocations_deposits_add',
    'pickupLocations_withdrawals_add',
    'pickupLocations_delete',
    'stores_orders_add',
    'payments_sections_add'
  ]
}
Vue.prototype.$checkRole = function (userRole) {
  const siteRole = Object.entries(roles).find(([key, valueArray]) =>
    valueArray.includes(userRole)
  )?.[0]
  return (
    (siteRole == 'global' || Vue.prototype.$site[siteRole]) &&
    (Vue.prototype.$user.admin || Vue.prototype.$user.role[userRole])
  )
}
Vue.prototype.$checkMultiRoles = function (roles) {
  let result = false
  roles.map((role) => {
    const roleResult = Vue.prototype.$checkRole(role)
    if (roleResult) result = true
  })
  return result
}
